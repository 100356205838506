{
    "error_screen__renderer_failed": "Your graphics card may not support UpTogether! Please try on again on a WebGL2 enabled browser & device.",
    "discord_reward": "Özel bir cilt kilidini açmak için Discord'umuza katılın!",
    "error_screen__server_maybe_full": "Sunucuya bağlanırken hata oluştu: sunucular şu anda dolu olabilir, birkaç dakika sonra tekrar deneyin!",
    "error_screen__maintenance_mode": "UpTogether.io güncelleniyor! Yeni şeyleri denemek için birkaç dakika sonra geri gel 🎉",
    "error_screen__client_out_of_date": "Oyun istemciniz güncel değil! Lütfen oyun istemcinizi en son sürüme güncelleyin ve tekrar deneyin.",
    "error_screen__invalid_connection": "Sunucu kullanıcınızı reddetti! Lütfen tekrar deneyin.",
    "error_screen__already_logged_in": "Zaten giriş yaptınız!",
    "error_screen__unknown_reason": "Bilinmeyen bağlantı hatası :(",
    "error_screen__disconnected_from_server": "Sunucudan bağlantı kesildi! Sunucular güncelleme için kapatılabilir. Birkaç dakika sonra tekrar deneyin.",
    "heightmarker__to_bottom": "En Aşağıya Dön!",
    "heightmarker__inspiration_come_far": "Geldiğin yere bak!",
    "heightmarker__inspiration_keep_going": "Devam et!",
    "heightmarker__inspiration_up_up": "Yukarı! yukarı! yukarı!",
    "in_game__secret_edward": "Merhaba Edward!!",
    "heightmarker__secret_gorillas": "Kaç goril bulabilirsin??",
    "heightmarker__change_character": "Karakter Değiştir",
    "heightmarker__change_name": "İsim Değiştir",
    "heightmarker__change_trail": "Bir iz al!",
    "heightmarker__global_leaderboard": "Global Liderlik Tablosu",
    "heightmarker__your_stats": "SENİN İSTATİSTİKLERİN",
    "heightmarker__game_stats": "OYUN İSTATİSTİKLERİ",
    "heightmarker__tutorial_speed": "Hız",
    "heightmarker_tutorial_boost": "Hızlandır!",
    "heightmarker__tutorial_climb": "TIRMAN",
    "heightmarker__tutorial_the_wall": "DUVAR",
    "heightmarker__tutorial_bouncy": "Zıplayan!",
    "in_game__secret_invis_maze": "Labirent kapısının yanında, solda...",
    "in_game__secret_upside_down_plate": "Ters çevrilmiş tabaktan ileriye doğru atla!",
    "in_game__tutorial_start_timer": "Zamanlayıcıyı başlatmak için başlangıç çizgisini geç",
    "in_game__tutorial_invis_platforms": "Görünmez platformlar var!",
    "in_game__how_fast_top": "Zirveye ne kadar hızlı çıkabilirsin?",
    "in_game__no_touchy": "DOKUNMA!",
    "in_game__tutorial_running": "Koşmak için sol joystick'i kullan",
    "in_game__tutorial_tap": "Dokun",
    "in_game__tutorial_press": "Bas",
    "in_game__tutorial_or": "veya",
    "in_game__tutorial__to_jump": "Zıplamak için",
    "in_game__tutorial__hold": "Tut",
    "in_game__tutorial__jump_repeatedly": "Tekrarlayarak zıplamak için",
    "in_game__tutorial_wall_climb": "Duvarlara tırmanmak için",
    "in_game__tutorial_while_running": "Koşarken",
    "in_game__tutorial_drag_move_camera": "Kamerayı hareket ettirmek için ekrana sürükleyin",
    "in_game__checkpoint": "Kontrol noktası!",
    "loading_text__connecting": "Sunucuya bağlanıyor...",
    "loading_text__splines": "Eğrileri düzenliyor",
    "loading_text__tofu": "Tofuyu marine ediyor",
    "loading_text__corn": "Misir soyuyor",
    "loading_text__broccoli": "Brokolinin küçük ağaçlar olduğunu onaylıyor",
    "loading_text__brussel_sprouts": "Brüksel lahanasının lezzetli olduğuna oyuncuları ikna ediyor",
    "loading_text__secret_sauce": "Gizli sos ekliyor",
    "loading_text__digesting": "Sindirme",
    "loading_text__stirring": "Sürekli karıştırma",
    "loading_text__fixing_ice_cream": "McDonalds'ta dondurma makinesini tamir ediyor",
    "loading_text__chopping_broccoli": "Brokoliyi doğruyor",
    "loading_text__carmelize_apples": "Elmaları karamelize ediyor",
    "loading_text__water_boil": "Su kaynatıyor",
    "loading_text__filleting_fish": "Balığı filetoya ayırıyor",
    "loading_text__plating": "Tabaklama",
    "loading_text__garnishing": "Süsleme",
    "loading_text__moldy_bits": "Küflü kısımları kesiyor",
    "death_block__almost": "Neredeyse!",
    "death_block__close_one": "Yakın!",
    "death_block__try_again": "Tekrar dene!",
    "death_block__roasted": "Kavrulmuş!",
    "death_block__burnt": "Yanmış!",
    "death_block__oh_no": "Oh Hayır!",
    "death_block__grilled": "Izgara!",
    "death_block__blanched": "Haşlanmış!",
    "death_block__crisp": "Çıtır!",
    "death_block__flameed": "Flambé edildi!",
    "death_block__order_up": "Sipariş ver!",
    "death_block__ouch": "Auç!",
    "death_block__good_try": "İyi Deneme!",
    "death_block__baked": "Fırınlandı!",
    "death_block__crusty": "Çıtır!",
    "death_block__next_time": "Bir Sonraki Sefer!",
    "death_block__toast": "Sen kızartıldın!",
    "death_block__no_chef": "Şef yok!",
    "death_block__singe_left": "Sadece sola biraz yanık!",
    "death_block__oof": "Oof!",
    "death_block__fried": "Düşüp kızaran!",
    "death_block__recipe_ruined": "Tarif mahvoldu",
    "death_block__sizzling_stop": "Sızzz sesi durdu!",
    "death_block__defeated": "Şef mağlup!",
    "death_block__burnt_out": "Tükenmiş mi?",
    "death_block__meltdown": "Erime halinde mi?",
    "falling_death__slippery": "Kaygan!",
    "falling_death__gg": "GG",
    "falling_death__you_died": "Öldün ",
    "falling_death__f": "F",
    "falling_death__floor_food": "Zemin yemeği!",
    "falling_death__three_second": "Üç saniye kuralı!",
    "falling_death__floor_seasoning": "Hmm! Zemin baharatı!",
    "falling_death__just_missed": "Dirençli!",
    "falling_death__rip": "RIP",
    "falling_death__floor_vs_flavor": "Zemin: 1",
    "falling_death__floor_fall": "Zemin Başarısız!",
    "falling_death__saucy_slip": "Soslu kayma!",
    "falling_death__fried_fell": "Kızartıldı ve düştü!",
    "falling_death__dive": "Tatsız dalış!",
    "falling_death__floor_finish": "Zemin bitişi!",
    "falling_death__dinner_dive": "Akşam yemeği dalışı!",
    "falling_death__splat": "SPLAT!",
    "falling_death__oops": "Hata",
    "falling_death__greasy_spot": "Yağlı noktayı buldun!",
    "falling_death__barely_missed": "Zar zor kaçırıldı!",
    "falling_death__down_we_go": "Aşağı iniyoruz!",
    "button__play": "Oyna!",
    "restart__title": "Yeniden Başlat?",
    "restart__are_you_sure": "Oyununuzu yeniden başlatmak istediğinizden emin misiniz?",
    "restart__confirm": "Evet!",
    "restart__restarted": "Yeniden başlatıldı...",
    "leaderboard__players": "Oyuncular",
    "leaderboard__countries": "Ülkeler",
    "leaderboard__falls": "Düşüşler",
    "leaderboard__hours_played": "Oynanan Saatler",
    "leaderboard__got_to_top": "Zirveye Çıktı",
    "leaderboard__personal_best": "Kişisel En İyi",
    "leaderboard__global_rank": "Küresel Sıralama",
    "leaderboard__rank": "Sıralama",
    "leaderboard__runs": "Oyunlar",
    "leaderboard__time_played": "Oynama Süresi"
}

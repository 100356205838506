import { ChangeUsernameCommand, EmoteCommand, NType } from "../../shared/SharedNetcodeSchemas";
import { getHighResolutionTimestampMsNewNew, secondsToTime } from "../../shared/SharedUtils";
import { Characters } from "../../shared/data/CharacterData";
import { Emotes, GetRandomEmoteAnimation, getEmoteFromEmoteString } from "../../shared/data/EmoteData";
import { Countries, UsernamePrefixesInt, UsernameSuffixesInt } from "../../shared/data/Usernames";
import { GameplaySystem } from "../../shared/engine/SharedGameplaySystem";
import "animate.css";
import { getCountryCodeFromCountry } from "../ui/PlayerLabel";
import { HostPortals } from "../ClientTypes";
import { ParticleEffects } from "../../shared/data/ParticleData";
import { LSKeys, loadFromLocalStorage, localStorageContainsKey, saveToLocalStorage } from "./ClientLocalStorage";
import { t } from "../../shared/data/Data_I18N";

export enum Screens {
    None = 0,
    CharacterSelect,
    ChangeUsername,
    RunComplete,
    EmojiSelector,
    TrailSelect,
    RestartRunWarning,
    ViewSettings
}

// Function to generate HTML options
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function generateEnumOptions(enumType: any): string {
    let optionsHTML = "";
    for (const key in enumType) {
        if (typeof enumType[key] === "number") {
            optionsHTML += `<option value="${enumType[key]}">${addSpacesBeforeCapitalLetters(key)}</option>`;
        }
    }
    return optionsHTML;
}

function addSpacesBeforeCapitalLetters(inputString: string): string {
    // Use a regular expression to find capital letters and insert a space before them
    const stringWithSpaces = inputString.replace(/([A-Z])/g, " $1");
    return stringWithSpaces;
}

const holidaySkins = [Characters.Turkeyleg, Characters.Candycane];
const themeSkins = process.env.ASSET_THEME === "CHRISTMAS" ? holidaySkins : [];

const holidayParticleEffects = [ParticleEffects.Snow, ParticleEffects.GingerbreadMan];
const themeParticles = process.env.ASSET_THEME === "CHRISTMAS" ? holidayParticleEffects : [];

export class ClientUI extends GameplaySystem {
    private readonly loadingTextOptions: string[] = ["loading_text__splines", "loading_text__tofu", "loading_text__corn", "loading_text__broccoli", "loading_text__brussel_sprouts", "loading_text__secret_sauce", "loading_text__digesting", "loading_text__stirring", "loading_text__fixing_ice_cream", "loading_text__chopping_broccoli", "loading_text__carmelize_apples", "loading_text__water_boil", "loading_text__filleting_fish", "loading_text__plating", "loading_text__garnishing", "loading_text__moldy_bits"];

    private readonly deathBlockDeathTextOptions: string[] = ["death_block__almost", "death_block__close_one", "death_block__try_again", "death_block__roasted", "death_block__burnt", "death_block__oh_no", "death_block__grilled", "death_block__blanched", "death_block__crisp", "death_block__flameed", "death_block__fried", "death_block__order_up", "death_block__ouch", "death_block__good_try", "death_block__baked", "death_block__crusty", "death_block__next_time", "death_block__toast", "death_block__no_chef", "death_block__singe_left", "death_block__oof", "death_block__recipe_ruined", "death_block__sizzling_stop", "death_block__defeated", "death_block__burnt_out", "death_block__meltdown"];

    private readonly fallingDeathTextOptions = ["falling_death__slippery", "falling_death__gg", "falling_death__you_died", "falling_death__f", "falling_death__floor_food", "falling_death__three_second", "falling_death__floor_seasoning", "falling_death__just_missed", "falling_death__rip", "falling_death__floor_vs_flavor", "falling_death__floor_fall", "falling_death__saucy_slip", "falling_death__fried_fell", "falling_death__dive", "falling_death__floor_finish", "falling_death__dinner_dive", "falling_death__splat", "falling_death__oops", "falling_death__greasy_spot", "falling_death__barely_missed", "falling_death__down_we_go"];

    private activeCarouselCharacterIndex: number = 0;
    private activeCarouselTrailIndex: number = 0;

    private readonly characterSelectCarouselCharacters: Characters[] = [Characters.Broccoli, Characters.Blueberry, Characters.Apple, Characters.Cupcake, Characters.Icecream, Characters.Hotdog, Characters.Peach, Characters.Eggplant, Characters.Banana, Characters.Baguette, Characters.Taco, ...themeSkins, Characters.Bacon];

    // @ts-ignore
    private readonly trailSelectCarouselOptions: ParticleEffects[] = [ParticleEffects.Broccolis, ParticleEffects.Circles, ParticleEffects.Flies, ParticleEffects.GoldenFlies, ParticleEffects.Hearts, ParticleEffects.Smoke, ParticleEffects.SquareStars, ParticleEffects.Stars, ...themeParticles];

    private readonly intentionalRespawnTextOptions = ["Oof"];

    private _pointerIsLocked: boolean = false;
    private _lastClickedPlayButtonTimestamp: number = 0;

    private selectedTrail: ParticleEffects = ParticleEffects.None;
    private showingPlayButtonPending: boolean;
    private _pointerWasUnlockedAt: number = 0;
    shopDiscordInfoDomElement: any;

    public getSelectedTrail(): ParticleEffects {
        return this.selectedTrail;
    }
    public setSelectedTrail(trail: ParticleEffects) {
        this.selectedTrail = trail;
    }

    private changeUsernameBtnDomElement: HTMLElement | null = null;
    private countryImages: NodeListOf<HTMLImageElement> | null = null;
    private usernameCountryOptions: HTMLSelectElement | null = null;
    private usernamePrefixOptions: HTMLSelectElement | null = null;
    private usernameSuffixOptions: HTMLSelectElement | null = null;
    private emojiSelectorDomElement: HTMLElement | null = null;
    private rcMinsDomElement: HTMLElement | null = null;
    private rcSecsDomElement: HTMLElement | null = null;
    private rcMsDomElement: HTMLElement | null = null;
    private goAgainButtonDomElement: HTMLElement | null = null;
    private runCompleteDomElement: HTMLElement | null = null;
    private fallsValueDomElement: HTMLElement | null = null;
    private uiScreenOpenOverlayDomElement: HTMLElement | null = null;
    private topleftHudDomElement: HTMLElement | null = null;
    private usernameScreenDomElement: HTMLElement | null = null;
    private uiPopLoaderDomElement: HTMLElement | null = null;
    private uiPopLoaderProgressDomElement: HTMLElement | null = null;
    private closeScreenButtonsDomElements: NodeListOf<HTMLElement>;
    private keyboardHotkeyIndicators: NodeListOf<HTMLElement> | null = null;
    private emojiButtonsDomElements: NodeListOf<HTMLImageElement>;
    private restartRunWarningDomElement: HTMLElement | null = null;
    private restartRunTitleDomElement: HTMLElement | null = null;
    private restartRunWarningTextDomElement: HTMLElement | null = null;
    private characterSelectDomElement: HTMLElement | null = null;
    private trailSelectDomElement: HTMLElement | null = null;
    private alertTextDomElement: HTMLElement | null = null;
    private gameHudDomElement: HTMLElement | null = null;
    private loadingProgressDomElement: HTMLElement | null = null;
    private loadingSplashScreenDomElement: HTMLElement | null = null;
    private loadingBarTextElement: HTMLElement | null = null;
    private playButtonDomElement: HTMLElement | null = null;
    private runDurationDomElement: HTMLElement | null = null;
    private jumpButtonDomElement: HTMLElement | null = null;
    private joystickContainerDomElement: HTMLElement | null = null;
    private muteMusicBtnDomElement: HTMLElement | null = null;
    private muteSfxBtnDomElement: HTMLElement | null = null;
    private settingsBtnDomElement: HTMLElement | null = null;
    private restartCheckpointBtnDomElement: HTMLElement | null = null;
    private restartEntireRunBtnDomElement: HTMLElement | null = null;
    private musicMutedIndicatorDomElement: HTMLElement | null = null;
    private sfxMutedIndicatorDomElement: HTMLElement | null = null;
    private heightValueDomElement: HTMLElement | null = null;
    private fsButtonDomElement: HTMLElement | null = null;
    private discordButtonDomElement: HTMLElement | null = null;
    private discordInfoDomElement: HTMLElement | null = null;
    private openEmojiScreenDomElement: HTMLElement | null = null;
    private viewSettingsDomElement: HTMLElement | null = null;
    private openRestartWarningScreenDomElement: HTMLElement | null = null;
    private restartCheckpointButtonPressedThisFrame: boolean = false;
    private restartEntireRunButtonPressedThisFrame: boolean = false;
    private rootUIDomElement: HTMLElement | null = null;

    private trailActiveIndicatorDomElement: HTMLElement | null = null;
    private characterActiveIndicatorDomElement: HTMLElement | null = null;
    private xHotKeyIndicatorDomElement: HTMLElement | null = null;
    private rHotKeyIndicatorDomElement: HTMLElement | null = null;

    private unlockCharacterBtnDomElement: HTMLElement | null = null;
    private selectCharacterBtnDomElement: HTMLElement | null = null;
    private moveCharacterCarouselLeftBtnDomElement: HTMLElement | null = null;
    private moveCharacterCarouselRightBtnDomElement: HTMLElement | null = null;
    private characterCarouselLeftImgDomElement: HTMLImageElement | null = null;
    private characterCarouselLeftNameDomElement: HTMLElement | null = null;
    private characterCarouselCenterImgDomElement: HTMLImageElement | null = null;
    private characterCarouselCenterNameDomElement: HTMLElement | null = null;
    private characterCarouselRightImgDomElement: HTMLImageElement | null = null;
    private characterCarouselRightNameDomElement: HTMLElement | null = null;
    private loadingParent: HTMLElement | null = null;
    private gameLogoElement: HTMLElement | null = null;

    private errorScreenDomElement: HTMLElement | null = null;
    private errorMsgDomElement: HTMLElement | null = null;
    private selectTrailBtnDomElement: HTMLElement | null = null;
    private removeTrailBtnDomElement: HTMLElement | null = null;
    private unlockTrailBtnDomElement: HTMLElement | null = null;
    private moveTrailCarouselLeftBtnDomElement: HTMLElement | null = null;
    private moveTrailCarouselRightBtnDomElement: HTMLElement | null = null;
    private trailCarouselLeftImgDomElement: HTMLImageElement | null = null;
    private trailCarouselCenterImgDomElement: HTMLImageElement | null = null;
    private trailCarouselRightImgDomElement: HTMLImageElement | null = null;

    private unlockedCharacterSkins: number[] = [];
    private unlockedTrails: number[] = [];

    private numberOfFalls: number = 0;

    private activeScreen: Screens = Screens.None;
    private lastClosedAllScreensTimestamp: number = getHighResolutionTimestampMsNewNew();

    public constructor() {
        super();

        this.changeUsernameBtnDomElement = document.querySelector(".change-username-btn");
        this.countryImages = document.querySelectorAll(".flag-preview");
        this.keyboardHotkeyIndicators = document.querySelectorAll(".keyboard-keypress");
        this.usernameCountryOptions = document.querySelector(".change-username-country");
        this.usernamePrefixOptions = document.querySelector(".change-username-prefix");
        this.usernameSuffixOptions = document.querySelector(".change-username-suffix");
        this.emojiSelectorDomElement = document.querySelector(".emoji-selector");
        this.rcMinsDomElement = document.querySelector(".rc-mins");
        this.rcSecsDomElement = document.querySelector(".rc-secs");
        this.rcMsDomElement = document.querySelector(".rc-ms");
        this.goAgainButtonDomElement = document.querySelector(".run-complete-btn");
        this.runCompleteDomElement = document.querySelector(".run-complete");

        this.trailActiveIndicatorDomElement = document.querySelector(".trail-active");
        this.characterActiveIndicatorDomElement = document.querySelector(".character-active");

        this.xHotKeyIndicatorDomElement = document.querySelector(".keyboard-keypress.x");
        this.rHotKeyIndicatorDomElement = document.querySelector(".keyboard-keypress.r");
        this.gameLogoElement = document.querySelector(".game-logo");
        this.characterSelectDomElement = document.querySelector(".character-select");
        this.restartRunWarningDomElement = document.querySelector(".restart-run-warning");
        this.restartRunTitleDomElement = document.querySelector(".restart-run-title");
        this.restartRunWarningTextDomElement = document.querySelector(".warning");
        this.unlockCharacterBtnDomElement = document.querySelector(".unlock-character-btn");

        this.selectCharacterBtnDomElement = document.querySelector(".select-character-btn");
        this.characterCarouselLeftImgDomElement = document.querySelector(".character-carousel-left-img");
        this.characterCarouselLeftNameDomElement = document.querySelector(".character-carousel-left-name");
        this.characterCarouselCenterImgDomElement = document.querySelector(".character-carousel-center-img");
        this.characterCarouselCenterNameDomElement = document.querySelector(".character-carousel-center-name");
        this.characterCarouselRightImgDomElement = document.querySelector(".character-carousel-right-img");
        this.characterCarouselRightNameDomElement = document.querySelector(".character-carousel-right-name");
        this.moveCharacterCarouselLeftBtnDomElement = document.querySelector(".character-scroll-left-btn");
        this.moveCharacterCarouselRightBtnDomElement = document.querySelector(".character-scroll-right-btn");

        this.trailSelectDomElement = document.querySelector(".trail-select");
        this.selectTrailBtnDomElement = document.querySelector(".select-trail-btn");
        this.removeTrailBtnDomElement = document.querySelector(".remove-trail-btn");
        this.unlockTrailBtnDomElement = document.querySelector(".unlock-trail-btn");
        this.trailCarouselLeftImgDomElement = document.querySelector(".trail-carousel-left-img");
        this.trailCarouselCenterImgDomElement = document.querySelector(".trail-carousel-center-img");
        this.trailCarouselRightImgDomElement = document.querySelector(".trail-carousel-right-img");
        this.moveTrailCarouselLeftBtnDomElement = document.querySelector(".trail-scroll-left-btn");
        this.moveTrailCarouselRightBtnDomElement = document.querySelector(".trail-scroll-right-btn");

        this.fallsValueDomElement = document.querySelector(".falls-value");
        this.uiScreenOpenOverlayDomElement = document.querySelector(".ui-screen-open-overlay");
        this.openEmojiScreenDomElement = document.querySelector(".emoji-btn");
        this.openRestartWarningScreenDomElement = document.querySelector(".restart-run-btn");
        this.viewSettingsDomElement = document.querySelector(".view-settings");
        this.settingsBtnDomElement = document.querySelector(".settings-btn");

        this.fsButtonDomElement = document.querySelector(".fs-btn");
        this.discordButtonDomElement = document.querySelector(".discord-container");
        this.discordInfoDomElement = document.querySelector(".discord-info");

        this.shopDiscordInfoDomElement = document.querySelector(".unlock-discord-btn");

        this.jumpButtonDomElement = document.querySelector(".jump-button-container");
        this.joystickContainerDomElement = document.querySelector(".joystick-container");
        this.topleftHudDomElement = document.querySelector(".top-left-hud");
        this.usernameScreenDomElement = document.querySelector(".change-username");
        this.uiPopLoaderDomElement = document.querySelector(".ui-pop-loader");
        this.uiPopLoaderProgressDomElement = document.querySelector(".ui-pop-loader-progress");
        this.closeScreenButtonsDomElements = document.querySelectorAll(".close-screen");
        this.emojiButtonsDomElements = document.querySelectorAll(".emoji");
        this.alertTextDomElement = document.querySelector(".alert");
        this.gameHudDomElement = document.querySelector(".hud");
        this.loadingParent = document.querySelector(".loading");
        this.loadingProgressDomElement = document.querySelector(".loading-bar-fg");
        this.loadingSplashScreenDomElement = document.querySelector(".loading-splash");
        this.playButtonDomElement = document.querySelector(".play-btn");
        this.loadingBarTextElement = document.querySelector(".loading-text");
        this.runDurationDomElement = document.querySelector(".run-duration");
        this.muteMusicBtnDomElement = document.querySelector(".mute-music-btn");
        this.muteSfxBtnDomElement = document.querySelector(".mute-sfx-btn");
        this.restartCheckpointBtnDomElement = document.querySelector(".restart-cp-btn");
        this.restartEntireRunBtnDomElement = document.querySelector(".restart-run-btn-confirm");
        this.musicMutedIndicatorDomElement = document.querySelector(".music-off");
        this.sfxMutedIndicatorDomElement = document.querySelector(".sfx-off");
        this.heightValueDomElement = document.querySelector(".height-value");
        this.rootUIDomElement = document.querySelector("#ui-root");
        this.errorScreenDomElement = document.querySelector(".error-screen");
        this.errorMsgDomElement = document.querySelector(".error-msg");

        if (this.rHotKeyIndicatorDomElement === null) {
            throw new Error("Could not find R hotkey indicator dom element!");
        }

        if (this.xHotKeyIndicatorDomElement === null) {
            throw new Error("Could not find X hotkey indicator dom element!");
        }

        if (this.errorScreenDomElement === null) {
            throw new Error("Could not find error screen dom element!");
        }

        if (this.errorMsgDomElement === null) {
            throw new Error("Could not find error msg dom element!");
        }

        if (this.usernameCountryOptions === null) {
            throw new Error("Could not find username country options!");
        }

        if (this.loadingParent === null) {
            throw new Error("Could not find loading parent dom element!");
        }

        if (this.changeUsernameBtnDomElement === null) {
            throw new Error("Could not find change username button dom element!");
        }

        if (this.countryImages.length === 0) {
            throw new Error("Could not find country images!");
        }

        if (this.usernamePrefixOptions === null) {
            throw new Error("Could not find username prefix options!");
        }

        if (this.usernameSuffixOptions === null) {
            throw new Error("Could not find username suffix options!");
        }

        if (this.emojiSelectorDomElement === null) {
            throw new Error("Could not find emoji selector dom element!");
        }

        if (this.emojiSelectorDomElement === null) {
            throw new Error("Could not find emoji selector dom element!");
        }

        if (this.rcMinsDomElement === null) {
            throw new Error("Could not find rc mins dom element!");
        }

        if (this.rcSecsDomElement === null) {
            throw new Error("Could not find rc secs dom element!");
        }

        if (this.rcMsDomElement === null) {
            throw new Error("Could not find rc ms dom element!");
        }

        if (this.characterCarouselLeftImgDomElement === null) {
            throw new Error("Could not find carousel left img dom element!");
        }

        if (this.characterCarouselLeftNameDomElement === null) {
            throw new Error("Could not find carousel left name dom element!");
        }

        if (this.characterCarouselCenterImgDomElement === null) {
            throw new Error("Could not find carousel center img dom element!");
        }

        if (this.characterCarouselCenterNameDomElement === null) {
            throw new Error("Could not find carousel center name dom element!");
        }

        if (this.characterCarouselRightImgDomElement === null) {
            throw new Error("Could not find carousel right img dom element!");
        }

        if (this.characterCarouselRightNameDomElement === null) {
            throw new Error("Could not find carousel right name dom element!");
        }

        if (this.unlockCharacterBtnDomElement === null) {
            throw new Error("Could not find unlock character button dom element!");
        }

        if (this.selectCharacterBtnDomElement === null) {
            throw new Error("Could not find select character button dom element!");
        }

        if (this.moveCharacterCarouselLeftBtnDomElement === null) {
            throw new Error("Could not find move character carousel left button dom element!");
        }

        if (this.moveCharacterCarouselRightBtnDomElement === null) {
            throw new Error("Could not find move character carousel right button dom element!");
        }

        if (this.fallsValueDomElement === null) {
            throw new Error("Could not find falls value dom element!");
        }

        if (this.rootUIDomElement === null) {
            throw new Error("Could not find root ui dom element!");
        }

        if (this.uiScreenOpenOverlayDomElement === null) {
            throw new Error("Could not find ui screen open overlay dom element!");
        }

        if (this.fsButtonDomElement === null) {
            throw new Error("Could not find fs button dom element!");
        }

        if (this.discordButtonDomElement === null) {
            throw new Error("Could not find fs button dom element!");
        }

        if (this.shopDiscordInfoDomElement === null) {
            throw new Error("Could not find shop discord info dom element!");
        }

        if (this.discordInfoDomElement === null) {
            throw new Error("Could not find discord info dom element!");
        }

        if (this.heightValueDomElement === null) {
            throw new Error("Could not find height value dom element!");
        }

        if (this.musicMutedIndicatorDomElement === null) {
            throw new Error("Could not find music muted indicator dom element!");
        }

        if (this.sfxMutedIndicatorDomElement === null) {
            throw new Error("Could not find sfx muted indicator dom element!");
        }

        if (this.muteMusicBtnDomElement === null) {
            throw new Error("Could not find mute music button dom element!");
        }

        if (this.muteSfxBtnDomElement === null) {
            throw new Error("Could not find mute sfx button dom element!");
        }

        if (this.settingsBtnDomElement === null) {
            throw new Error("Could not find settings button dom element!");
        }

        if (this.restartCheckpointBtnDomElement === null) {
            throw new Error("Could not find restart run button dom element!");
        }

        if (this.restartEntireRunBtnDomElement === null) {
            throw new Error("Could not find restart entire run button dom element!");
        }

        if (this.jumpButtonDomElement === null) {
            throw new Error("Could not find jump button dom element!");
        }

        if (this.joystickContainerDomElement === null) {
            throw new Error("Could not find joystick container dom element!");
        }

        if (this.topleftHudDomElement === null) {
            throw new Error("Could not find top left hud dom element!");
        }

        if (this.usernameScreenDomElement === null) {
            throw new Error("Could not find username screen dom element!");
        }

        if (this.uiPopLoaderDomElement === null) {
            throw new Error("Could not find ui pop loader dom element!");
        }

        if (this.uiPopLoaderProgressDomElement === null) {
            throw new Error("Could not find ui pop loader progress dom element!");
        }

        if (this.closeScreenButtonsDomElements.length === 0) {
            throw new Error("Could not find close screen button dom elements!");
        }

        if (this.emojiButtonsDomElements.length === 0) {
            throw new Error("Could not find emoji button dom elements!");
        }

        if (this.characterSelectDomElement === null) {
            throw new Error("Could not find character select dom element!");
        }

        if (this.trailSelectDomElement === null) {
            throw new Error("Could not find character select dom element!");
        }

        if (this.alertTextDomElement === null) {
            throw new Error("Could not find alert text dom element!");
        }

        if (this.gameHudDomElement === null) {
            throw new Error("Could not find game hud dom element!");
        }

        if (this.loadingProgressDomElement === null) {
            throw new Error("Could not find loading progress dom element!");
        }

        if (this.gameLogoElement === null) {
            throw new Error("Could not find game logo dom element!");
        }

        if (this.loadingSplashScreenDomElement === null) {
            throw new Error("Could not find loading splash screen dom element!");
        }

        if (this.playButtonDomElement === null) {
            throw new Error("Could not find play button dom element!");
        }

        if (this.loadingBarTextElement === null) {
            throw new Error("Could not find loading bar text element!");
        }

        if (this.runDurationDomElement === null) {
            throw new Error("Could not find run duration dom element!");
        }
    }

    public ShowErrorScreen(errorMsg: string, shouldRedify: boolean = false): void {
        this.loadingSplashScreenDomElement!.setAttribute("style", "display: flex !important; opacity: 1 !important;");
        this.gameLogoElement!.setAttribute("style", "display: none !important; opacity: 0 !important;");

        this.loadingParent!.setAttribute("style", "display: none !important; opacity: 0 !important;");
        this.errorMsgDomElement!.innerText = errorMsg;

        if (shouldRedify) {
            this.errorMsgDomElement!.classList.add("error");
        }
        this.errorScreenDomElement!.setAttribute("style", "display: flex !important;");
        this.gameHudDomElement!.setAttribute("style", "display: none !important; opacity: 0 !important;");
    }

    public override Initialize(): void {
        document.addEventListener("pointerlockchange", this._onPointerLockStatusChanged.bind(this), false);
        document.addEventListener("mozpointerlockchange", this._onPointerLockStatusChanged.bind(this), false);
        document.addEventListener("webkitpointerlockchange", this._onPointerLockStatusChanged.bind(this), false);

        this.playButtonDomElement!.innerHTML = t("button__play");
        this.restartRunTitleDomElement!.innerHTML = t("restart__title");
        this.restartRunWarningTextDomElement!.innerHTML = t("restart__are_you_sure");
        this.restartEntireRunBtnDomElement!.innerHTML = t("restart__confirm");
        // Character Skin state init

        let foundUnlockedSkins: number[] | undefined = undefined;

        if (localStorageContainsKey(LSKeys.SkinUnlocks)) {
            const savedSkinUnlocks = loadFromLocalStorage(LSKeys.SkinUnlocks);

            try {
                const parsedUnlocks = JSON.parse(savedSkinUnlocks || "[]");

                // console.info("Got saved skin unlocks:", parsedUnlocks);

                foundUnlockedSkins = parsedUnlocks;
            } catch (e) {
                console.error("Failed to parse saved skin unlocks:", savedSkinUnlocks);
                saveToLocalStorage(LSKeys.SkinUnlocks, JSON.stringify([]));
            }
        } else {
            const defaultUnlocks: number[] = [];

            // console.info("No saved skin unlocks found, using default:", defaultUnlocks);

            saveToLocalStorage(LSKeys.SkinUnlocks, JSON.stringify([]));

            foundUnlockedSkins = defaultUnlocks;
        }

        // console.info("Found unlocks after parsing!!", foundUnlockedSkins);

        this.unlockedCharacterSkins = foundUnlockedSkins!;

        // Trail state init

        let foundTrailUnlocks: number[] | undefined = undefined;

        if (localStorageContainsKey(LSKeys.TrailUnlocks)) {
            const savedTrailUnlocks = loadFromLocalStorage(LSKeys.TrailUnlocks);

            try {
                const parsedUnlocks = JSON.parse(savedTrailUnlocks || "[]");

                // console.info("Got saved trail unlocks:", parsedUnlocks);

                foundTrailUnlocks = parsedUnlocks;
            } catch (e) {
                console.error("Failed to parse saved trail unlocks:", savedTrailUnlocks);
                saveToLocalStorage(LSKeys.TrailUnlocks, JSON.stringify([]));
            }
        } else {
            const defaultUnlocks: number[] = [];

            // console.info("No saved trail unlocks found, using default:", defaultUnlocks);

            saveToLocalStorage(LSKeys.TrailUnlocks, JSON.stringify([]));

            foundTrailUnlocks = defaultUnlocks;
        }

        // console.info("Found trail unlocks after parsing!!", foundTrailUnlocks);
        this.unlockedTrails = foundTrailUnlocks!;

        this.SetMusicMuteButtonState(Game.Audio.MusicMuted);

        this.SetSFXMuteButtonState(Game.Audio.SFXMuted);

        this._updateCharacterCarouselState();

        this._updateTrailCarouselState();

        this.alertTextDomElement!.addEventListener("animationend", this._onAlertTextEntryAnimationComplete.bind(this));

        this.moveCharacterCarouselLeftBtnDomElement!.addEventListener("click", () => {
            this.MoveCharacterSelectCarouselLeft();
        });

        this.moveCharacterCarouselRightBtnDomElement!.addEventListener("click", () => {
            this.MoveCharacterSelectCarouselRight();
        });

        this.discordInfoDomElement!.innerText = t("discord_reward");

        this.discordButtonDomElement!.addEventListener("click", () => {
            this._unlockCharacter(Characters.Bacon);
        });

        this.shopDiscordInfoDomElement!.addEventListener("click", this._selectCharacter.bind(this));

        this.unlockCharacterBtnDomElement!.addEventListener("click", this._selectCharacter.bind(this));

        this.selectCharacterBtnDomElement!.addEventListener("click", this._selectCharacter.bind(this));

        this.characterCarouselLeftImgDomElement!.addEventListener("click", () => {
            this.MoveCharacterSelectCarouselLeft();
        });

        this.characterCarouselRightImgDomElement!.addEventListener("click", () => {
            this.MoveCharacterSelectCarouselRight();
        });

        this.moveTrailCarouselLeftBtnDomElement!.addEventListener("click", () => {
            this.MoveTrailSelectCarouselLeft();
        });

        this.moveTrailCarouselRightBtnDomElement!.addEventListener("click", () => {
            this.MoveTrailSelectCarouselRight();
        });

        this.selectTrailBtnDomElement!.addEventListener("click", this._selectTrail.bind(this));
        this.unlockTrailBtnDomElement!.addEventListener("click", this._selectTrail.bind(this));

        this.removeTrailBtnDomElement!.addEventListener("click", this._removeTrail.bind(this));

        this.trailCarouselLeftImgDomElement!.addEventListener("click", () => {
            this.MoveTrailSelectCarouselLeft();
        });

        this.trailCarouselRightImgDomElement!.addEventListener("click", () => {
            this.MoveTrailSelectCarouselRight();
        });

        // Generate the options and append them to the select element
        this.usernameCountryOptions!.innerHTML = generateEnumOptions(Countries);
        this.usernamePrefixOptions!.innerHTML = generateEnumOptions(UsernamePrefixesInt);
        this.usernameSuffixOptions!.innerHTML = generateEnumOptions(UsernameSuffixesInt);

        this.usernameCountryOptions!.addEventListener("change", () => {
            const selectedValue = this.usernameCountryOptions!.value;
            // console.log(`Selected value: ${selectedValue}`);

            const imgSrcForCountry = `ui/flags/${getCountryCodeFromCountry(parseInt(selectedValue))}.png`;

            this.countryImages!.forEach((countryImage) => {
                countryImage.src = imgSrcForCountry;
            });
        });

        this.fsButtonDomElement!.addEventListener("click", () => {
            Game.Input.ToggleFullScreen();
        });

        this.changeUsernameBtnDomElement!.addEventListener("click", () => {
            Game.EmitEvent("SDK::PopRewardedAd", () => {
                // console.warn("callback for ad play success as defined in ClientUI (username change case)!");
                const country = parseInt(this.usernameCountryOptions!.value);
                const prefix = parseInt(this.usernamePrefixOptions!.value);
                const suffix = parseInt(this.usernameSuffixOptions!.value);

                const changeUsernameCommand: ChangeUsernameCommand = {
                    ntype: NType.ChangeUsernameCommand,
                    country,
                    prefix,
                    suffix
                };

                Game.UI.HideSpecificScreen(Screens.ChangeUsername);

                Game.Netcode.SendCommand(changeUsernameCommand);
            });
        });

        this.openEmojiScreenDomElement!.addEventListener("click", () => {
            this.ShowScreen(Screens.EmojiSelector);
        });

        this.openRestartWarningScreenDomElement!.addEventListener("click", () => {
            this.ShowScreen(Screens.RestartRunWarning);
        });

        this.closeScreenButtonsDomElements.forEach((closeScreenButton) => {
            closeScreenButton.addEventListener("click", () => {
                this.HideAllScreens();
            });
        });

        this.emojiButtonsDomElements.forEach((emojiButton) => {
            emojiButton.addEventListener("click", () => {
                const emoji = emojiButton.dataset.emoji;

                if (emoji === undefined) return;

                const emote: Emotes = getEmoteFromEmoteString(emoji);

                this.PopEmote(emote);

                this.HideAllScreens();
            });
        });

        this.goAgainButtonDomElement!.addEventListener("click", () => {
            setTimeout(() => {
                // Reload the page
                window.location.reload();
            }, 300);
        });

        this.playButtonDomElement!.addEventListener("click", () => {
            this._lastClickedPlayButtonTimestamp = Date.now();

            if (!globalThis.IsMobile) {
                try {
                    Game.Renderer.Canvas.requestPointerLock();
                } catch (e) {
                    console.error(`Caught pointer lock error, ignoring it: code: ${e.code} - message: ${e.message}`);
                }
            }

            if (Game.Predictor.GetPredictedEntity() !== undefined) {
                Game.Predictor.GetPredictedEntity().ShouldFireGameplayStartEventOnNextMovement = true;
            }

            // Game.EmitEvent("SDK::GameplayStart");
        });

        this.muteMusicBtnDomElement!.addEventListener("click", () => {
            Game.Audio.ToggleMusicMute();

            this.SetMusicMuteButtonState(Game.Audio.MusicMuted);
        });

        this.muteSfxBtnDomElement!.addEventListener("click", () => {
            Game.Audio.ToggleSFXMute();

            this.SetSFXMuteButtonState(Game.Audio.SFXMuted);
        });

        this.settingsBtnDomElement!.addEventListener("click", () => {
            this.ShowScreen(Screens.ViewSettings);
            // console.log("Settings btn pressed!");
        });

        this.restartCheckpointBtnDomElement!.addEventListener("click", () => {
            // console.log("Restart cp btn pressed!");
            this.restartCheckpointButtonPressedThisFrame = true;
        });

        // this.showRestartWarningBtnDomEleement!.addEventListener("click", () => {

        this.restartEntireRunBtnDomElement!.addEventListener("click", () => {
            // console.log("@@@");
            // console.log("Restart run btn pressed!");
            this.restartEntireRunButtonPressedThisFrame = true;
            this.HideAllScreens();
        });

        Game.ListenForEvent("UI::FadeOutLoadingScreen", this._fadeOutLoadingScreen.bind(this));

        Game.ListenForEvent("Loading::ProgressUpdated", this._updateLoadingProgressPercentage.bind(this));

        Game.ListenForEvent("Loading::AlmostComplete", this._onLoadingAlmostComplete.bind(this));

        Game.EmitEvent("LoadEvent", { eventName: "UIReady" });

        // Hide irrelevant elements for mobile
        if (globalThis.IsMobile) {
            this.playButtonDomElement!.style.display = "none";

            this.keyboardHotkeyIndicators!.forEach((keyboardHotkeyIndicator) => {
                keyboardHotkeyIndicator.style.display = "none";
            });

            this.fsButtonDomElement!.style.display = "none";
        }

        if (globalThis.HostPortal === HostPortals.Poki) {
            this.fsButtonDomElement!.style.display = "none";
        }

        if (globalThis.HostPortal === HostPortals.DIRECT) {
            // Create a new script tag and append to the body
            const scriptTag = document.createElement("script");
            scriptTag.type = "text/javascript";
            scriptTag.src = "https://app.termly.io/resource-blocker/1a630229-9f24-4599-971b-f89592149eaf?autoBlock=on";
            document.body.appendChild(scriptTag);

            // Create the cookie policy link and append it to the .view-settings-inner element
            const cookiePolicyLink = document.createElement("p");
            cookiePolicyLink.style.display = "block !important";
            cookiePolicyLink.classList.add("privacy-policy");
            // eslint-disable-next-line quotes
            cookiePolicyLink.innerHTML = `<a href="https://app.termly.io/document/cookie-policy/dd913ca4-a877-4205-a1bf-74176b817d30" target="_blank">Cookie Policy</a>`;
            document.querySelector(".view-settings-inner")!.appendChild(cookiePolicyLink);
        }
    }

    public PopEmote(emote: Emotes): void {
        const emoteCommand: EmoteCommand = {
            ntype: NType.EmoteCommand,
            emote,
            animation: GetRandomEmoteAnimation()
        };
        Game.Netcode.SendCommand(emoteCommand);
    }

    public ShowRunRestartHUDButtons(): void {
        this.restartCheckpointBtnDomElement!.style.display = "block";
        // this.rHotKeyIndicatorDomElement!.style.display = "block";
        this.openRestartWarningScreenDomElement!.style.display = "block";
        // this.xHotKeyIndicatorDomElement!.style.display = "block";
    }

    public HideRunRestartHUDButtons(): void {
        this.restartCheckpointBtnDomElement!.style.display = "none";
        // this.rHotKeyIndicatorDomElement!.style.display = "none";
        this.openRestartWarningScreenDomElement!.style.display = "none";
        // this.xHotKeyIndicatorDomElement!.style.display = "none";
    }

    public MoveTrailSelectCarouselLeft(): void {
        // console.log("Sending carousel left");
        const activeTrailIndex = this.activeCarouselTrailIndex;
        const activeTrail = this.trailSelectCarouselOptions[activeTrailIndex];
        const leftTrail = this._getLeftCarouselTrailFromCenterTrail(activeTrail);

        // console.log("Moving trail select carousel left");

        // console.log({ activeTrailIndex, activeTrail: ParticleEffects[activeTrail], leftTrail: ParticleEffects[leftTrail] });

        this.activeCarouselTrailIndex = this.trailSelectCarouselOptions.indexOf(leftTrail);

        this._updateTrailCarouselState();
    }

    public MoveTrailSelectCarouselRight(): void {
        // console.log("Sending carousel right");
        const activeTrailIndex = this.activeCarouselTrailIndex;
        const activeTrail = this.trailSelectCarouselOptions[activeTrailIndex];
        const rightTrail = this._getRightCarouselTrailFromCenterTrail(activeTrail);

        // console.log("Moving trail select carousel right");

        // console.log({ activeTrailIndex, activeTrail: ParticleEffects[activeTrail], rightTrail: ParticleEffects[rightTrail] });

        this.activeCarouselTrailIndex = this.trailSelectCarouselOptions.indexOf(rightTrail);

        this._updateTrailCarouselState();
    }

    public MoveCharacterSelectCarouselLeft(): void {
        // console.log("Sending carousel left");
        const activeCharacterIndex = this.activeCarouselCharacterIndex;
        const activeCharacter = this.characterSelectCarouselCharacters[activeCharacterIndex];
        const leftCharacter = this._getLeftCarouselCharacterFromCenterCharacter(activeCharacter);

        this.activeCarouselCharacterIndex = this.characterSelectCarouselCharacters.indexOf(leftCharacter);

        this._updateCharacterCarouselState();
    }

    public MoveCharacterSelectCarouselRight(): void {
        // console.log("Sending carousel right");
        const activeCharacterIndex = this.activeCarouselCharacterIndex;
        const activeCharacter = this.characterSelectCarouselCharacters[activeCharacterIndex];
        const rightCharacter = this._getRightCarouselCharacterFromCenterCharacter(activeCharacter);

        this.activeCarouselCharacterIndex = this.characterSelectCarouselCharacters.indexOf(rightCharacter);

        this._updateCharacterCarouselState();
    }

    public UpdateFinalRunTime(rcMins: string, rcSecs: string, rcMs: string): void {
        this.rcMinsDomElement!.innerText = rcMins;
        this.rcSecsDomElement!.innerText = rcSecs;
        this.rcMsDomElement!.innerText = rcMs;
        this.runDurationDomElement!.classList.add("complete");
    }

    private _unlockCharacter(chosenCharacter: Characters): void {
        // console.log("Trying to unlock:", chosenCharacter);

        if (this.unlockedCharacterSkins.includes(chosenCharacter)) {
            // console.log("Character is already unlocked! changing");

            Game.Netcode.ChangeSkin(chosenCharacter);
        } else {
            // console.warn("callback for ad play success as defined in ClientUI (character select case)!");
            Game.Netcode.ChangeSkin(chosenCharacter);

            try {
                const currentSavedUnlocks = loadFromLocalStorage(LSKeys.SkinUnlocks);

                const parsedUnlocks = JSON.parse(currentSavedUnlocks || "[]");

                parsedUnlocks.push(chosenCharacter);

                this.unlockedCharacterSkins = parsedUnlocks;

                saveToLocalStorage(LSKeys.SkinUnlocks, JSON.stringify(parsedUnlocks));
            } catch (e) {
                console.error("Error updating local storage of saved unlocked skins!");
            }
        }
        Game.UI.HideSpecificScreen(Screens.ViewSettings);
    }

    private _selectCharacter(): void {
        const chosenCharacter: Characters = this.characterSelectCarouselCharacters[this.activeCarouselCharacterIndex];

        // console.log("Trying to unlock:", chosenCharacter);

        if (chosenCharacter === Characters.Bacon) {
            this._unlockCharacter(chosenCharacter);
            Game.UI.HideSpecificScreen(Screens.CharacterSelect);
        } else {
            if (this.unlockedCharacterSkins.includes(chosenCharacter)) {
                // console.log("Character is already unlocked! changing");

                Game.Netcode.ChangeSkin(chosenCharacter);

                this._updateCharacterCarouselState();
            } else {
                Game.EmitEvent("SDK::PopRewardedAd", () => {
                    // console.warn("callback for ad play success as defined in ClientUI (character select case)!");
                    Game.Netcode.ChangeSkin(chosenCharacter);

                    try {
                        const currentSavedUnlocks = loadFromLocalStorage(LSKeys.SkinUnlocks);

                        const parsedUnlocks = JSON.parse(currentSavedUnlocks || "[]");

                        parsedUnlocks.push(chosenCharacter);

                        this.unlockedCharacterSkins = parsedUnlocks;

                        saveToLocalStorage(LSKeys.SkinUnlocks, JSON.stringify(parsedUnlocks));

                        this._updateCharacterCarouselState();

                        Game.UI.HideSpecificScreen(Screens.CharacterSelect);
                    } catch (e) {
                        console.error("Error updating local storage of saved unlocked skins!");
                    }
                });
            }
        }
    }

    private _removeTrail(): void {
        this.selectedTrail = ParticleEffects.None;

        Game.Netcode.ChangeTrail(ParticleEffects.None);
        this._updateTrailCarouselState();
    }

    private _selectTrail(): void {
        // console.log("SELECTING TRAIL::", ParticleEffects[this.trailSelectCarouselOptions[this.activeCarouselTrailIndex]]);

        const chosenTrail = this.trailSelectCarouselOptions[this.activeCarouselTrailIndex];

        this.selectedTrail = chosenTrail;

        // console.log("Trying to unlock trail:", chosenTrail);

        if (this.unlockedTrails.includes(chosenTrail)) {
            // console.log("Trail is already unlocked! changing");

            Game.Netcode.ChangeTrail(chosenTrail);

            this._updateTrailCarouselState();
        } else {
            Game.EmitEvent("SDK::PopRewardedAd", () => {
                // console.warn("callback for ad play success as defined in ClientUI (trail select case)!");

                Game.Netcode.ChangeTrail(chosenTrail);

                try {
                    const currentSavedUnlocks = loadFromLocalStorage(LSKeys.TrailUnlocks)!;

                    const parsedUnlocks = JSON.parse(currentSavedUnlocks || "[]");

                    parsedUnlocks.push(chosenTrail);

                    this.unlockedTrails = parsedUnlocks;

                    saveToLocalStorage(LSKeys.TrailUnlocks, JSON.stringify(parsedUnlocks));

                    this._updateTrailCarouselState();

                    Game.UI.HideSpecificScreen(Screens.TrailSelect);
                } catch (e) {
                    console.error("Error updating local storage of saved unlocked trails!");
                }
            });
        }
    }

    private _getLeftCarouselTrailFromCenterTrail(centerTrail: ParticleEffects): ParticleEffects {
        // console.log(`Current center trail: num ${centerTrail} str ${ParticleEffects[centerTrail]} index ${this.trailSelectCarouselOptions.indexOf(centerTrail)}`);
        if (centerTrail === ParticleEffects.Broccolis) {
            /* TODO HOLIDAY UPDATE: Swap these two */
            return ParticleEffects.Stars;
            //return ParticleEffects.GingerbreadMan;
        }

        return centerTrail - 1;
    }

    private _getRightCarouselTrailFromCenterTrail(centerTrail: ParticleEffects): ParticleEffects {
        // console.log(`Current center trail: num ${centerTrail} str ${ParticleEffects[centerTrail]} index ${this.trailSelectCarouselOptions.indexOf(centerTrail)}`);

        /* TODO HOLIDAY UPDATE: Swap these two */
        if (centerTrail === ParticleEffects.Stars) {
            //if (centerTrail === ParticleEffects.GingerbreadMan) {
            return ParticleEffects.Broccolis;
        }

        return centerTrail + 1;
    }

    private _getLeftCarouselCharacterFromCenterCharacter(centerCharacter: Characters): Characters {
        if (centerCharacter === 0) {
            /* TODO HOLIDAY UPDATE: Swap these two */
            return Object.keys(Characters).length / 2 - 1;
            // return Characters.Candycane;
        }

        return centerCharacter - 1;
    }

    private _getRightCarouselCharacterFromCenterCharacter(centerCharacter: Characters): Characters {
        /* TODO HOLIDAY UPDATE: Swap these two */
        if (centerCharacter === Object.keys(Characters).length / 2 - 1) {
            // if (centerCharacter === Characters.Candycane) {
            return 0;
        }

        return centerCharacter + 1;
    }

    private _updateCharacterCarouselState(): void {
        const centerCharacter = this.characterSelectCarouselCharacters[this.activeCarouselCharacterIndex];
        const leftCharacter = this._getLeftCarouselCharacterFromCenterCharacter(centerCharacter);
        const rightCharacter = this._getRightCarouselCharacterFromCenterCharacter(centerCharacter);

        const activeSkin = Game.GetActiveSkin();

        // console.log(activeSkin);

        // console.log(centerCharacter);

        this.unlockCharacterBtnDomElement!.style.display = "none";
        this.selectCharacterBtnDomElement!.style.display = "none";
        this.characterActiveIndicatorDomElement!.style.display = "none";
        this.shopDiscordInfoDomElement!.style.display = "none";

        if (activeSkin === centerCharacter) {
            // console.warn("Center character is the same as our character skin!");

            this.characterActiveIndicatorDomElement!.style.display = "flex";
        } else {
            if (this.unlockedCharacterSkins.includes(centerCharacter)) {
                this.selectCharacterBtnDomElement!.style.display = "flex";
            } else {
                if (centerCharacter === Characters.Bacon) {
                    this.shopDiscordInfoDomElement!.style.display = "flex";
                } else {
                    this.unlockCharacterBtnDomElement!.style.display = "flex";
                }
            }
        }

        this.characterCarouselLeftImgDomElement!.src = `ui/characters/${process.env.ASSET_THEME}/${Characters[leftCharacter].toLowerCase()}_plate_unlit_001.png`;
        this.characterCarouselLeftNameDomElement!.innerText = Characters[leftCharacter];

        this.characterCarouselCenterImgDomElement!.src = `ui/characters/${process.env.ASSET_THEME}/${Characters[centerCharacter].toLowerCase()}_plate_unlit_001.png`;
        this.characterCarouselCenterNameDomElement!.innerText = Characters[centerCharacter];

        this.characterCarouselRightImgDomElement!.src = `ui/characters/${process.env.ASSET_THEME}/${Characters[rightCharacter].toLowerCase()}_plate_unlit_001.png`;
        this.characterCarouselRightNameDomElement!.innerText = Characters[rightCharacter];
    }

    private _updateTrailCarouselState(): void {
        const centerTrail = this.trailSelectCarouselOptions[this.activeCarouselTrailIndex];
        const leftTrail = this._getLeftCarouselTrailFromCenterTrail(centerTrail);
        const rightTrail = this._getRightCarouselTrailFromCenterTrail(centerTrail);

        // console.warn("Updating trail carousel state!!");

        // console.log("New left trail:", ParticleEffects[leftTrail]);
        // console.log("New center trail:", ParticleEffects[centerTrail]);
        // console.log("New right trail:", ParticleEffects[rightTrail]);

        // console.log("Currently selected trail index:", this.selectedTrail);
        // console.log("Currently selected trail string:", ParticleEffects[this.selectedTrail]);

        // console.log(`Left trail as png is: ${ParticleEffects[leftTrail]}.png`);

        // console.log(`Center trail as png is: ${ParticleEffects[centerTrail]}.png`);

        // console.log(`Right trail as png is: ${ParticleEffects[rightTrail]}.png`);

        if (this.selectedTrail !== ParticleEffects.None) {
            this.removeTrailBtnDomElement!.style.display = "flex";
        } else {
            this.removeTrailBtnDomElement!.style.display = "none";
        }

        if (this.selectedTrail === centerTrail) {
            // console.warn("Center trail is the same as our trail skin!");

            this.selectTrailBtnDomElement!.style.display = "none";
            this.unlockTrailBtnDomElement!.style.display = "none";
            this.trailActiveIndicatorDomElement!.style.display = "flex";
        } else {
            this.trailActiveIndicatorDomElement!.style.display = "none";

            if (this.unlockedTrails.includes(centerTrail)) {
                //If this trail is the selected trail, display remove. Else display select
                this.unlockTrailBtnDomElement!.style.display = "none";
                this.selectTrailBtnDomElement!.style.display = "flex";
            } else {
                this.selectTrailBtnDomElement!.style.display = "none";
                this.unlockTrailBtnDomElement!.style.display = "flex";
            }
        }

        this.trailCarouselLeftImgDomElement!.src = `ui/trail/${ParticleEffects[leftTrail]}.png`;
        //console.log("Left: ", `ui/trail/${ParticleEffects[leftTrail]}.png`);

        this.trailCarouselCenterImgDomElement!.src = `ui/trail/${ParticleEffects[centerTrail]}.png`;
        //console.log("Centre: ", `ui/trail/${ParticleEffects[centerTrail]}.png`);

        this.trailCarouselRightImgDomElement!.src = `ui/trail/${ParticleEffects[rightTrail]}.png`;
        //console.log("Right: ", `ui/trail/${ParticleEffects[rightTrail]}.png`);
    }

    public UIScreenIsOpen(): boolean {
        // console.log(Screens[this.activeScreen]);

        const uiScreenIsAnythingButNone = this.activeScreen !== Screens.None;
        const playButtonDomElementIsFlexOrBlock = this.playButtonDomElement!.style.display === "flex" || this.playButtonDomElement!.style.display === "block";
        const loadingSplashScreenDomElementIsFlexOrBlock = this.loadingSplashScreenDomElement!.style.display === "flex" || this.loadingSplashScreenDomElement!.style.display === "block";

        return uiScreenIsAnythingButNone || playButtonDomElementIsFlexOrBlock || loadingSplashScreenDomElementIsFlexOrBlock;
    }

    public UpdateUIPopAccumulator(newAcc: number): void {
        if (newAcc === 0 || newAcc >= 1) {
            this.uiPopLoaderDomElement!.style.display = "none";
        } else {
            this.uiPopLoaderDomElement!.style.display = "flex";
            this.uiPopLoaderProgressDomElement!.style.width = `${newAcc * 100}%`;
        }
    }

    public UpdateHeightValue(newHeight: number): void {
        this.heightValueDomElement!.innerText = newHeight.toString();
    }

    public PressedRestartCheckpointButtonThisFrame(): boolean {
        return this.restartCheckpointButtonPressedThisFrame;
    }

    public PressedRestartRunButtonThisFrame(): boolean {
        return this.restartEntireRunButtonPressedThisFrame;
    }

    public ResetDeaths(): void {
        this.numberOfFalls = 0;
        this.fallsValueDomElement!.innerText = this.numberOfFalls.toString();
    }

    public IncrementDeaths(): void {
        this.numberOfFalls++;
        this.fallsValueDomElement!.innerText = this.numberOfFalls.toString();
    }

    public SetSFXMuteButtonState(SFXMuted: boolean): void {
        if (SFXMuted) {
            this.sfxMutedIndicatorDomElement!.style.display = "flex";
        } else {
            this.sfxMutedIndicatorDomElement!.style.display = "none";
        }
    }

    public SetMusicMuteButtonState(MusicMuted: boolean): void {
        if (MusicMuted) {
            this.musicMutedIndicatorDomElement!.style.display = "flex";
        } else {
            this.musicMutedIndicatorDomElement!.style.display = "none";
        }
    }

    public PopText(textToPop: string): void {
        this._showAlertText(textToPop);
    }

    public PopFallingDeathText(): void {
        const chosenString = t(this.fallingDeathTextOptions[Math.floor(Math.random() * this.fallingDeathTextOptions.length)]);

        this._showAlertText(chosenString);
    }

    public PopRestartedEntireRunText(): void {
        this._showAlertText(t("restart__restarted"));
    }

    public PopIntentionalRespawnText(): void {
        const chosenString = this.intentionalRespawnTextOptions[Math.floor(Math.random() * this.intentionalRespawnTextOptions.length)];

        this._showAlertText(chosenString);
    }

    public PopCookedDeathText(): void {
        const chosenString = t(this.deathBlockDeathTextOptions[Math.floor(Math.random() * this.deathBlockDeathTextOptions.length)]);

        this._showAlertText(chosenString);
    }

    public UpdateRunDuration(newDuration: number): void {
        this.runDurationDomElement!.innerText = secondsToTime(newDuration);
    }

    private _onAlertTextEntryAnimationComplete(): void {
        // this.alertTextDomElement!.removeEventListener("animationend", this._onAlertTextEntryAnimationComplete.bind(this));

        this.alertTextDomElement!.classList.remove("animate__jackInTheBox");

        // this.alertTextDomElement!.addEventListener("animationend", this._onAlertTextExitAnimationComplete.bind(this));

        this.alertTextDomElement!.classList.add("animate__fadeOut", "animate__fast");
    }

    private _showAlertText(newText: string) {
        this.alertTextDomElement!.innerText = newText;

        this.alertTextDomElement!.classList.remove("animate__fadeOut", "animate__fast");

        this.alertTextDomElement!.style.display = "flex";
        this.alertTextDomElement!.classList.add("animate__jackInTheBox");
    }

    public PointerIsCurrentlyLocked(): boolean {
        // @ts-ignore
        return this._pointerIsLocked;
    }

    private _onPointerLockStatusChanged(): void {
        // console.log("Pointer lock status changed!!");
        // console.log(document.pointerLockElement);
        // console.log(this.activeScreen);

        console.log("POINTER LOCK STATUS CHANGED!!!");

        // @ts-ignore
        if (document.pointerLockElement === null || document.mozPointerLockElement === null || document.webkitPointerLockElement === null) {
            console.log("Pointer is not locked anymore!!");
            this._pointerWasUnlockedAt = Date.now();
            this._pointerIsLocked = false;
            Game.EmitEvent("SDK::GameplayStop");
        } else {
            console.log("Pointer is not locked!!!!!");
            this._pointerIsLocked = true;

            if (Game.Predictor.GetPredictedEntity() !== undefined) {
                Game.Predictor.GetPredictedEntity().ShouldFireGameplayStartEventOnNextMovement = true;
            }

            // Game.EmitEvent("SDK::GameplayStart");
            this._hidePlayButton();
        }
    }

    private _showPlayButton(): void {
        if (this.showingPlayButtonPending === true) return;

        if (globalThis.IsMobile === false) {
            this.showingPlayButtonPending = true;
            this.showingPlayButtonPending = false;
            this.playButtonDomElement!.style.display = "flex";
        }
    }

    private _hidePlayButton(): void {
        this.playButtonDomElement!.style.display = "none";
    }

    public CancelPointerLock(): void {
        if (globalThis.IsMobile === false) {
            document.exitPointerLock();
        }
    }

    public HideMobileControls(): void {
        this.jumpButtonDomElement!.style.display = "none";
        this.joystickContainerDomElement!.style.display = "none";
    }

    public ShowMobileControls(): void {
        this.jumpButtonDomElement!.style.display = "flex";
        this.joystickContainerDomElement!.style.display = "flex";
    }

    public ShowScreen(screen: Screens): void {
        // console.log(`Showing screen: ${Screens[screen]}`);

        if (getHighResolutionTimestampMsNewNew() - this.lastClosedAllScreensTimestamp < 1500) {
            // console.log("Not showing screen because we just closed all screens within the last 1.5 seconds!");
            return;
        } else {
            // console.info("Emitting gameplay stop 2!");
            Game.EmitEvent("SDK::GameplayStop");

            // console.log("Showing screen");
            if (globalThis.IsMobile === false) {
                document.exitPointerLock();
            }

            this.uiScreenOpenOverlayDomElement!.style.display = "flex";

            if (globalThis.IsMobile) {
                this.HideMobileControls();
            } else {
                this.playButtonDomElement!.style.display = "none";
            }

            if (screen === Screens.RestartRunWarning) {
                if (this.activeScreen === Screens.RestartRunWarning) return;

                this.restartRunWarningDomElement!.style.display = "flex";

                this.activeScreen = screen;
            }

            if (screen === Screens.ViewSettings) {
                if (this.activeScreen === Screens.ViewSettings) return;

                this.viewSettingsDomElement!.style.display = "flex";

                this.activeScreen = screen;
            }

            if (screen === Screens.CharacterSelect) {
                if (this.activeScreen === Screens.CharacterSelect) return;

                this._updateCharacterCarouselState();

                this.characterSelectDomElement!.style.display = "flex";

                this.activeScreen = screen;
            }

            if (screen === Screens.TrailSelect) {
                if (this.activeScreen === Screens.TrailSelect) return;

                this._updateTrailCarouselState();

                this.trailSelectDomElement!.style.display = "flex";

                this.activeScreen = screen;
            }

            if (screen === Screens.ChangeUsername) {
                if (this.activeScreen === Screens.ChangeUsername) return;

                this.usernameScreenDomElement!.style.display = "flex";

                this.activeScreen = screen;
            }

            if (screen === Screens.EmojiSelector) {
                if (this.activeScreen === Screens.EmojiSelector) return;

                this.emojiSelectorDomElement!.style.display = "flex";

                this.activeScreen = screen;
            }

            if (screen === Screens.RunComplete) {
                if (this.activeScreen === Screens.RunComplete) return;

                this.runCompleteDomElement!.style.display = "flex";

                this.activeScreen = screen;
            }
        }
    }

    public ToggleUI() {
        if (this.rootUIDomElement!.style.display === "none") {
            this.rootUIDomElement!.style.display = "flex";
        } else {
            this.rootUIDomElement!.style.display = "none";
        }
    }

    public HideSpecificScreen(screen: Screens): void {
        // console.log(`Hiding screen: ${Screens[screen]}`);

        if (screen === Screens.RestartRunWarning) {
            this.restartRunWarningDomElement!.style.display = "none";
        }

        if (screen === Screens.ViewSettings) {
            this.viewSettingsDomElement!.style.display = "none";
        }

        if (screen === Screens.CharacterSelect) {
            this.characterSelectDomElement!.style.display = "none";
        }

        if (screen === Screens.TrailSelect) {
            this.trailSelectDomElement!.style.display = "none";
        }

        if (screen === Screens.ChangeUsername) {
            this.usernameScreenDomElement!.style.display = "none";
        }

        if (screen === Screens.EmojiSelector) {
            this.emojiSelectorDomElement!.style.display = "none";
        }

        if (screen === Screens.RunComplete) {
            this.runCompleteDomElement!.style.display = "none";
        }

        if (globalThis.IsMobile) {
            this.ShowMobileControls();
        }

        this.uiScreenOpenOverlayDomElement!.style.display = "none";

        this.activeScreen = Screens.None;

        if (Game.Predictor.GetPredictedEntity() !== undefined) {
            Game.Predictor.GetPredictedEntity().ShouldFireGameplayStartEventOnNextMovement = true;
        }
    }

    public HideAllScreens(): void {
        this.uiScreenOpenOverlayDomElement!.style.display = "none";
        this.lastClosedAllScreensTimestamp = getHighResolutionTimestampMsNewNew();
        this.runCompleteDomElement!.style.display = "none";
        this.characterSelectDomElement!.style.display = "none";
        this.restartRunWarningDomElement!.style.display = "none";
        this.viewSettingsDomElement!.style.display = "none";
        this.trailSelectDomElement!.style.display = "none";
        this.usernameScreenDomElement!.style.display = "none";
        this.emojiSelectorDomElement!.style.display = "none";

        // if (globalThis.IsMobile === false) {
        //     try {
        //         Game.Renderer.Canvas.requestPointerLock();
        //     } catch (e) {
        //         console.error(`Caught pointer lock error, ignoring it: ${e.message}`);
        //     }
        // }

        if (this.playButtonDomElement!.style.display !== "flex") {
            if (globalThis.IsMobile) {
                this.playButtonDomElement!.style.display = "none";
            }
        } else {
            setTimeout(() => {
                this.playButtonDomElement!.style.display = "flex";
            }, 300);
        }

        if (globalThis.IsMobile) {
            this.ShowMobileControls();
        }

        this.activeScreen = Screens.None;

        if (Game.Predictor.GetPredictedEntity() !== undefined) {
            Game.Predictor.GetPredictedEntity().ShouldFireGameplayStartEventOnNextMovement = true;
        }
    }

    private _onLoadingAlmostComplete(updatedPercentage: number): void {
        // console.warn("Updating loading progress percentage to:", updatedPercentage);
        // console.log("Loader told us progress is now...", updatedPercentage);
        this.loadingBarTextElement!.innerText = t("loading_text__connecting");
        // @ts-ignore
        this.loadingProgressDomElement!.style["mask-size"] = `${updatedPercentage}% 300%`;
        // @ts-ignore
        this.loadingProgressDomElement!.style["-webkit-mask-size"] = `${updatedPercentage}% 300%`;
    }

    private _updateLoadingProgressPercentage(updatedPercentage: number): void {
        // console.warn("Updating loading progress percentage to:", updatedPercentage);
        // console.log("Loader told us progress is now...", updatedPercentage);
        this.loadingBarTextElement!.innerText = t(this.loadingTextOptions[Math.floor(Math.random() * this.loadingTextOptions.length)]) + "...";
        // @ts-ignore
        this.loadingProgressDomElement!.style["mask-size"] = `${updatedPercentage}% 300%`;
        // @ts-ignore
        this.loadingProgressDomElement!.style["-webkit-mask-size"] = `${updatedPercentage}% 300%`;
    }

    private _fadeInLoadingScreen(): void {
        // console.warn("Fading in loading screen");
        this.loadingSplashScreenDomElement!.style.display = "flex";
        this.loadingSplashScreenDomElement!.classList.add("animate__animated", "animate__fadeIn", "animate__fast");
    }

    private _fadeOutLoadingScreen(): void {
        // return;
        // console.warn("Fading out loading screen");
        // Fade out the this.loadingSplashScreenDomElement dom node over 1 second
        this.loadingSplashScreenDomElement!.classList.add("animate__animated", "animate__fadeOut", "animate__fast");

        this.loadingSplashScreenDomElement!.addEventListener("animationend", () => {
            // console.log("loading splash animation end fired");
            // this.loadingSplashScreenDomElement!.style.display = "none";

            this.gameHudDomElement!.style.display = "flex";
            this.gameHudDomElement!.classList.add("animate__animated", "animate__fadeIn");
        });
    }

    protected override getSystemName(): string {
        return "UI";
    }

    private _atLeastOneSecondHasPassedSincePointerUnlocked(): boolean {
        return Date.now() - this._pointerWasUnlockedAt > 1000;
    }

    public override Update(__deltaTimeS: number, __deltaTimeMS: number, __currentTime: Timestamp): void {
        this.restartCheckpointButtonPressedThisFrame = false;
        this.restartEntireRunButtonPressedThisFrame = false;

        if (globalThis.IsMobile === false) {
            if (this.PointerIsCurrentlyLocked() === false) {
                if (this.UIScreenIsOpen() === false) {
                    if (this._atLeastOneSecondHasPassedSincePointerUnlocked() === true) {
                        this._showPlayButton();
                    }
                }
            }
        }
    }

    public Cleanup(): void {
        this.LogInfo("Cleaning up...");
    }
}

import { Client, Interpolator } from "nengi";
import { GameplaySystem } from "../../shared/engine/SharedGameplaySystem";
import { ChangeSkinCommand, InputCommand, NType, NTyped, ParticleTrailCommand, nengiConfig } from "../../shared/SharedNetcodeSchemas";
import { WebSocketClientAdapter } from "nengi-websocket-client-adapter";
import { AnonymousUser, ServerConnectionErrorReasons } from "../../shared/SharedTypes";
import { ClientPlayer } from "../entities/ClientPlayer";
import { Config } from "../../shared/Config";
import { Characters } from "../../shared/data/CharacterData";
import { ParticleEffects } from "../../shared/data/ParticleData";
import { matchmakeRequestWithRetries } from "../ClientRequests";
import { t } from "../../shared/data/Data_I18N";
const { Server } = Config;

// Helper function to normalize angles to the range [-Math.PI, Math.PI]
function normalizeAngle(angle: number) {
    while (angle < -Math.PI) angle += 2 * Math.PI;
    while (angle > Math.PI) angle -= 2 * Math.PI;
    return angle;
}

export class ClientNetcodeSystem extends GameplaySystem {
    private client: Client;
    private interpolator: Interpolator;
    private predictionReady: boolean = false;
    private serverSideSelfReady: boolean = false;
    private isConnected: boolean = false;
    private myServerSideEntity: ClientPlayer;
    private windowHasFocus: boolean = true;
    private characterTurnSpeed = 16;

    public constructor() {
        super();

        Game.ListenForEvent("Identity::Identified", async (data) => {
            try {
                const { identity } = data as { identity: AnonymousUser };
                // console.log("((netcode)) Identity::Identified", identity);
                const { _id } = identity;

                if (window.location.href.includes("localhost:1234")) {
                    this.Connect("ws://localhost:8001", _id, Config.Game.GAME_VERSION);
                } else {
                    const matchmakingResult = await matchmakeRequestWithRetries(_id, Config.Game.GAME_VERSION);

                    if (matchmakingResult === undefined) throw new Error("Failed to matchmake!");

                    // console.info("Got matchmaking response from backend:", matchmakingResult);

                    const { server } = matchmakingResult;

                    this.Connect(server, _id, Config.Game.GAME_VERSION);
                }
            } catch (e) {
                console.error("An error occurred attempting to connect to the server!", e);

                Game.UI.ShowErrorScreen("Error connecting to server: servers may be full right now, try again in a few minutes!", true);
            }
        });

        Game.ListenForEvent("Prediction::PredictionReady", () => {
            this.predictionReady = true;
        });

        Game.ListenForEvent("Bootstrap::MyServerSideEntityIsFullyCreatedLocally", (event: { myEntity: ClientPlayer }) => {
            const { myEntity } = event;
            this.myServerSideEntity = myEntity;
            this.serverSideSelfReady = true;
        });

        this.client = new Client(nengiConfig, WebSocketClientAdapter, 1000 / Server.TARGET_DELTA_IN_MS);

        this.client.setDisconnectHandler((reason, event) => {
            console.log("disconnected", reason, event);

            Game.UI.ShowErrorScreen("Disconnected from server! The servers may be going down for an update. Try again in a few minutes.", true);
        });

        this.client.setWebsocketErrorHandler((event) => {
            console.log("ws error", event);

            Game.UI.ShowErrorScreen("Disconnected from server! The servers may be going down for an update. Try again in a few minutes.", true);
        });

        this.interpolator = new Interpolator(this.client);

        window.onfocus = () => {
            this.windowHasFocus = true;
        };
    }

    public override Initialize(): void {}

    protected override getSystemName(): string {
        return "Netcode";
    }

    public async Connect(serverAddress: string, connectionToken: string, gameClientVersion: string): Promise<void> {
        try {
            const res = await this.client.connect(serverAddress, { connectionToken: connectionToken, gameClientVersion: gameClientVersion });

            this.LogInfo(`Connection response: ${res}`);
            this.isConnected = true;

            if (process.env.NODE_ENV === "production") {
                window.addEventListener("beforeunload", (__e) => {
                    // TODO: accidental closing tab prevention
                });
            }

            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.client.adapter.socket.onerror = (event: any) => {
                console.log("Socket error!", event);
                Game.UI.ShowErrorScreen("Disconnected from server! The servers may be going down for an update. Try again in a few minutes.", true);
            };

            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.client.adapter.socket.addEventListener("error", (event: any) => {
                console.log("Socket error!", event);
                Game.UI.ShowErrorScreen("Disconnected from server! The servers may be going down for an update. Try again in a few minutes.", true);
            });

            // TODO: this will eventually be surfaced via the this.client interface, for now dig deep to find it
            this.client.adapter.socket.addEventListener("close", () => {
                Game.UI.ShowErrorScreen("Disconnected from server! The servers may be going down for an update. Try again in a few minutes.", true);

                console.log("Socket to server closed!");
                this.isConnected = false;
                // TODO: sudden disconnect graceful error handling
            });
        } catch (err) {
            this.LogError("connection error");
            this.LogError(typeof err);
            this.LogError(err);
            let reasonExplanation: string;
            if (err === ServerConnectionErrorReasons.GameClientOutOfDate) {
                reasonExplanation = t("error_screen__client_out_of_date");
            } else if (err === ServerConnectionErrorReasons.InvalidConnectionToken) {
                reasonExplanation = t("error_screen__invalid_connection");
            } else if (err === ServerConnectionErrorReasons.PlayerAlreadyLoggedIn) {
                reasonExplanation = t("error_screen__already_logged_in");
            } else {
                reasonExplanation = t("error_screen__unknown_reason");
            }
            console.log("Connection error:", reasonExplanation, err);

            Game.UI.ShowErrorScreen(t("error_screen__disconnected_from_server"), true);

            return;
        }

        Game.EmitEvent("LoadEvent", { eventName: "NetcodeReady" });
    }

    public ChangeSkin(character: Characters): void {
        const changeSkinCommand: ChangeSkinCommand = {
            ntype: NType.ChangeSkinCommand,
            newSkin: character
        };

        this.client.addCommand(changeSkinCommand);
    }

    public ChangeTrail(trail: ParticleEffects): void {
        const newParticleTrailSelection: ParticleTrailCommand = {
            ntype: NType.ParticleTrailCommand,
            particleTrail: trail
        };
        Game.Netcode.SendCommand(newParticleTrailSelection);
    }

    public Update(deltaTimeS: number, __deltaTimeMS: number, __currentTimestamp: number) {
        if (this.isConnected) {
            // Interpolate two server ticks worth of data
            const interpolatorState = this.interpolator.getInterpolatedState(Server.TARGET_DELTA_IN_MS * 2);

            while (this.client.network.messages.length > 0) {
                Game.ProcessMessage(this.client.network.messages.pop());
            }

            interpolatorState.forEach((snapshot) => {
                snapshot.createEntities.forEach((entityToCreate) => {
                    Game.AddEntity(entityToCreate);
                });

                snapshot.updateEntities.forEach((entityUpdatePayload) => {
                    Game.UpdateEntity(entityUpdatePayload);
                });

                snapshot.deleteEntities.forEach((nid: NetworkEntityId) => {
                    Game.DeleteEntity(nid);
                });
            });

            // Only send input commands if our server-side entity is both fully created locally, as well as alive, and hasnt extracted successfully
            if (this.serverSideSelfReady && this.windowHasFocus) {
                if (Game.Renderer.GetMyLocalEntity() !== undefined) {
                    // Movement inputs
                    const forward = Game.Input.IsKeyDown("KeyW") || Game.Input.IsKeyDown("ArrowUp"); //|| Game.Input.JoystickIsForward();
                    const back = Game.Input.IsKeyDown("KeyS") || Game.Input.IsKeyDown("ArrowDown"); //|| Game.Input.JoystickIsBack();
                    const left = Game.Input.IsKeyDown("KeyA") || Game.Input.IsKeyDown("ArrowLeft"); //|| Game.Input.JoystickIsLeft();
                    const right = Game.Input.IsKeyDown("KeyD") || Game.Input.IsKeyDown("ArrowRight"); //|| Game.Input.JoystickIsRight();

                    const wantsToRespawnAtCheckpoint = Game.Input.IsKeyDown("KeyR") || Game.UI.PressedRestartCheckpointButtonThisFrame();

                    // console.log("@@@ wantsToRespawnAtCheckpoint", wantsToRespawnAtCheckpoint);

                    const wantsToRestartRun = Game.UI.PressedRestartRunButtonThisFrame();

                    // if (wantsToRestartRun) {
                    //     console.log("@@@ wantsToRestartRun", wantsToRestartRun);
                    // }

                    const isMobile = IsMobile;
                    const playerIsMoving = Game.Input.MobilePlayerIsMoving;
                    const mobileXDirection = Game.Input.GetMobileXDirection();
                    const mobileZDirection = Game.Input.GetMobileZDirection();
                    const mobileYRotation = Game.Input.GetMobileRotationAngle();
                    const mobileJoystickStrengthMultiplier = Game.Input.GetMobileJoystickStrengthMovementMultiplier();

                    // console.log("Game.Input.IsKeyDown('Space')", Game.Input.IsKeyDown("Space"));
                    // console.log("Game.Input.VirtualJumpIsBeingPressed()", Game.Input.VirtualJumpIsBeingPressed());

                    let jump = false;

                    if (Game.Input.IsKeyDown("Space") || Game.Input.VirtualJumpIsBeingPressed()) {
                        jump = true;
                    }

                    const rotX = Game.Renderer.PlayerRotationProxyCube.rotation.x;
                    const rotY = Game.Renderer.PlayerRotationProxyCube.rotation.y;
                    const rotZ = Game.Renderer.PlayerRotationProxyCube.rotation.z;

                    let newRotY = rotY;
                    let oldRotY;

                    if (Config.Player.ENABLE_PREDICTION && Game.Predictor.GetPredictedEntity() !== undefined) {
                        oldRotY = Game.Predictor.GetPredictedEntity().rotY;
                    } else {
                        oldRotY = Game.Renderer.GetMyLocalEntity().rotY;
                    }

                    if (forward) {
                        if (right) {
                            // Calculate the new rotation for moving forward and to the right
                            newRotY = rotY - Math.PI / 4;
                        } else if (left) {
                            // Calculate the new rotation for moving forward and to the left
                            newRotY = rotY + Math.PI / 4;
                        } else {
                            // Calculate the new rotation for moving forward
                            newRotY = rotY;
                        }
                    } else if (back) {
                        if (right) {
                            // Calculate the new rotation for moving back and to the right
                            newRotY = rotY - (3 * Math.PI) / 4;
                        } else if (left) {
                            // Calculate the new rotation for moving back and to the left
                            newRotY = rotY + (3 * Math.PI) / 4;
                        } else {
                            // Calculate the opposite direction for moving back
                            newRotY = rotY + Math.PI;
                        }
                    } else if (right) {
                        // Calculate the new rotation for strafing right
                        newRotY = rotY - Math.PI / 2;
                    } else if (left) {
                        // Calculate the new rotation for strafing left
                        newRotY = rotY + Math.PI / 2;
                    } else {
                        newRotY = oldRotY;
                    }

                    // Calculate the shortest rotation difference
                    const rotationDifference = normalizeAngle(newRotY - oldRotY);

                    // Adjust the final rotation based on the shortest path and interpolate
                    const finalYRot = oldRotY + rotationDifference * (this.characterTurnSpeed * deltaTimeS);

                    let inputCommand: InputCommand;

                    if (Game.UI.PointerIsCurrentlyLocked()) {
                        // apply the rotation
                        Game.Renderer.UpdateLocalEntityRotation(rotX, finalYRot, rotZ);

                        inputCommand = {
                            ntype: NType.InputCommand,
                            forward,
                            back,
                            left,
                            right,
                            jump,
                            rotX,
                            rotY,
                            rotZ,
                            isMobile,
                            playerIsMoving,
                            mobileXDirection,
                            mobileZDirection,
                            mobileYRotation,
                            mobileSpeedMultiplier: mobileJoystickStrengthMultiplier,
                            visualModelRotation: finalYRot,
                            wantsToRespawnAtCheckpoint,
                            wantsToRestartRun,
                            delta: deltaTimeS
                        };
                    } else {
                        inputCommand = {
                            ntype: NType.InputCommand,
                            forward: false,
                            back: false,
                            left: false,
                            right: false,
                            jump,
                            rotX,
                            rotY,
                            rotZ,
                            isMobile,
                            playerIsMoving,
                            mobileXDirection,
                            mobileZDirection,
                            mobileYRotation,
                            mobileSpeedMultiplier: mobileJoystickStrengthMultiplier,
                            visualModelRotation: finalYRot,
                            wantsToRespawnAtCheckpoint,
                            wantsToRestartRun,
                            delta: deltaTimeS
                        };
                    }

                    if (Config.Player.ENABLE_PREDICTION && this.predictionReady) {
                        Game.Predictor.PredictPlayerInputCommand(inputCommand);
                    }

                    this.client.addCommand(inputCommand);
                }
            }

            this.client.flush();
        }
    }

    public SendCommand(command: NTyped): void {
        this.client.addCommand(command);
    }

    public Cleanup(): void {
        this.LogInfo("Cleaning up...");
    }
}

{
    "error_screen__renderer_failed": "Your graphics card may not support UpTogether! Please try on again on a WebGL2 enabled browser & device.",
    "discord_reward": "انضم إلى Discord الخاص بنا لفتح جلد خاص!",
    "error_screen__server_maybe_full": "خطأ في الاتصال بالخادم: قد تكون الخوادم ممتلئة الآن، حاول مرة أخرى في بضع دقائق!",
    "error_screen__maintenance_mode": "UpTogether.io يتم تحديثها! عد في بضع دقائق لتجربة الأمور الجديدة 🎉",
    "error_screen__client_out_of_date": "العميل الخاص بك قديم! يرجى تحديث عميل اللعبة إلى أحدث إصدار وحاول مرة أخرى.",
    "error_screen__invalid_connection": "رفض الخادم لمستخدمك! يرجى المحاولة مرة أخرى.",
    "error_screen__already_logged_in": "لقد سجلت بالفعل!",
    "error_screen__unknown_reason": "خطأ غير معروف في الاتصال :(",
    "error_screen__disconnected_from_server": "تم قطع الاتصال من الخادم! قد تكون الخوادم تتوقف للتحديث. حاول مرة أخرى في بضع دقائق.",
    "heightmarker__to_bottom": "العودة إلى الأسفل!",
    "heightmarker__inspiration_come_far": "انظر كم وصلت بعيدًا!",
    "heightmarker__inspiration_keep_going": "تابع!",
    "heightmarker__inspiration_up_up": "أعلى! أعلى! أعلى!",
    "in_game__secret_edward": "مرحبًا إدوارد!!",
    "heightmarker__secret_gorillas": "كم عدد الغوريلات التي يمكنك العثور عليها؟؟",
    "heightmarker__change_character": "تغيير الشخصية",
    "heightmarker__change_name": "تغيير الاسم",
    "heightmarker__change_trail": "احصل على مسار!",
    "heightmarker__global_leaderboard": "لوحة المتصدرين العالمية",
    "heightmarker__your_stats": "إحصائياتك",
    "heightmarker__game_stats": "إحصائيات اللعبة",
    "heightmarker__tutorial_speed": "السرعة",
    "heightmarker_tutorial_boost": "تسريع!",
    "heightmarker__tutorial_climb": "تسلق",
    "heightmarker__tutorial_the_wall": "الجدار",
    "heightmarker__tutorial_bouncy": "مرن!",
    "in_game__secret_invis_maze": "بجوار باب المتاهة، على اليمين...",
    "in_game__secret_upside_down_plate": "اقفز للأمام من لوحة مقلوبة!",
    "in_game__tutorial_start_timer": "تجاوز خط البداية لبدء المؤقت الخاص بك",
    "in_game__tutorial_invis_platforms": "هناك منصات غير مرئية!",
    "in_game__how_fast_top": "كم يمكنك الوصول إلى الأعلى بسرعة؟",
    "in_game__no_touchy": "لا تلمس!",
    "in_game__tutorial_running": "استخدم العصا اليسرى للركض",
    "in_game__tutorial_tap": "اضغط",
    "in_game__tutorial_press": "اضغط",
    "in_game__tutorial_or": "أو",
    "in_game__tutorial__to_jump": "للقفز",
    "in_game__tutorial__hold": "استمرار",
    "in_game__tutorial__jump_repeatedly": "للقفز بشكل متكرر",
    "in_game__tutorial_wall_climb": "لتسلق الجدران",
    "in_game__tutorial_while_running": "أثناء الركض",
    "in_game__tutorial_drag_move_camera": "اسحب الشاشة لتحريك الكاميرا",
    "in_game__checkpoint": "نقطة تفتيش",
    "loading_text__connecting": "الاتصال بالخادم...",
    "loading_text__splines": "ريتيكولاتينج سبلاينز",
    "loading_text__tofu": "تبديل التوفو",
    "loading_text__corn": "تقشير الذرة",
    "loading_text__broccoli": "التحقق من أن البروكلي هو شجرة صغيرة",
    "loading_text__brussel_sprouts": "إقناع اللاعبين بأن نباتات بروكسل لذيذة",
    "loading_text__secret_sauce": "إضافة الصلصة السرية",
    "loading_text__digesting": "هضم",
    "loading_text__stirring": "تحريك باستمرار",
    "loading_text__fixing_ice_cream": "إصلاح آلة الآيس كريم في ماكدونالدز",
    "loading_text__chopping_broccoli": "تقطيع البروكلي",
    "loading_text__carmelize_apples": "كرميل التفاح",
    "loading_text__water_boil": "مشاهدة الماء يغلي",
    "loading_text__filleting_fish": "تقسيم السمك",
    "loading_text__plating": "وضع على الصحن",
    "loading_text__garnishing": "تزيين",
    "loading_text__moldy_bits": "قطع الأجزاء المعافنة",
    "death_block__almost": "تقريبًا!",
    "death_block__close_one": "قريبًا",
    "death_block__try_again": "حاول مرة أخرى!",
    "death_block__roasted": "أنت محمص!",
    "death_block__burnt": "أنت محترق!",
    "death_block__oh_no": "آه لا!",
    "death_block__grilled": "أنت مشوي!",
    "death_block__blanched": "أنت مسلوق!",
    "death_block__crisp": "أنت هش!",
    "death_block__flameed": "أنت مشتعل!",
    "death_block__order_up": "الطلب جاهز!",
    "death_block__ouch": "أوتش!",
    "death_block__good_try": "محاولة جيدة!",
    "death_block__baked": "أنت خبز!",
    "death_block__crusty": "أنت هش!",
    "death_block__next_time": "المرة القادمة!",
    "death_block__toast": "أنت محمص!",
    "death_block__no_chef": "لا يوجد شيف!",
    "death_block__singe_left": "لمسة خفيفة إلى اليسار فقط!",
    "death_block__oof": "أوف!",
    "death_block__fried": "تلعثم وقلي!",
    "death_block__recipe_ruined": "الوصفة فسدت",
    "death_block__sizzling_stop": "توقف متسارع!",
    "death_block__defeated": "الطاهي هزم!",
    "death_block__burnt_out": "اندلعت!",
    "death_block__meltdown": "هل تواجه انهيارًا؟",
    "falling_death__slippery": "زلق!",
    "falling_death__gg": "جيد جدًا",
    "falling_death__you_died": "لقد مت",
    "falling_death__f": "إف",
    "falling_death__floor_food": "طعام الأرض!",
    "falling_death__three_second": "قاعدة الثلاث ثواني!",
    "falling_death__floor_seasoning": "مم! توابل الأرض!",
    "falling_death__just_missed": "فاته بشكل صغير!",
    "falling_death__rip": "ارقد بسلام",
    "falling_death__floor_vs_flavor": "الأرض: 1",
    "falling_death__floor_fall": "فشل على الأرض!",
    "falling_death__saucy_slip": "انزلاق لذيذ!",
    "falling_death__fried_fell": "قلي وسقط!",
    "falling_death__dive": "نزوة بدون طعم!",
    "falling_death__floor_finish": "انتهاء على الأرض!",
    "falling_death__dinner_dive": "نزوة العشاء!",
    "falling_death__splat": "سبلات!",
    "falling_death__oops": "عفوًا",
    "falling_death__greasy_spot": "لقد وجدت المكان الدهني!",
    "falling_death__barely_missed": "فاته بشكل ضئيل!",
    "falling_death__down_we_go": "ننزل",
    "button__play": "العب!",
    "restart__title": "هل تريد إعادة التشغيل؟",
    "restart__are_you_sure": "هل أنت متأكد من أنك تريد إعادة بدء الجولة؟",
    "restart__confirm": "نعم!",
    "restart__restarted": "تم إعادة التشغيل...",
    "leaderboard__players": "اللاعبون",
    "leaderboard__countries": "الدول",
    "leaderboard__falls": "السقوط",
    "leaderboard__hours_played": "ساعات اللعب",
    "leaderboard__got_to_top": "وصل إلى القمة",
    "leaderboard__personal_best": "الأفضل شخصياً",
    "leaderboard__global_rank": "الترتيب العالمي",
    "leaderboard__rank": "الترتيب",
    "leaderboard__runs": "الجولات",
    "leaderboard__time_played": "وقت اللعب"
}

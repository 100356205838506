{
    "error_screen__renderer_failed": "Your graphics card may not support UpTogether! Please try on again on a WebGL2 enabled browser & device.",
    "discord_reward": "Join our Discord to unlock a special skin!",
    "error_screen__server_maybe_full": "Error connecting to server: servers may be full right now, try again in a few minutes!",
    "error_screen__maintenance_mode": "UpTogether.io is being updated! Come back in a few minutes to try the new stuff 🎉",
    "error_screen__client_out_of_date": "Your game client is out of date! Please update your game client to the latest version and try again.",
    "error_screen__invalid_connection": "The server rejected your user! Please try again.",
    "error_screen__already_logged_in": "You're already logged in!",
    "error_screen__unknown_reason": "Unknown connection error :(",
    "error_screen__disconnected_from_server": "Disconnected from server! The servers may be going down for an update. Try again in a few minutes.",
    "heightmarker__to_bottom": "Back to Bottom!",
    "heightmarker__inspiration_come_far": "Look how far you've come!",
    "heightmarker__inspiration_keep_going": "Keep going!",
    "heightmarker__inspiration_up_up": "Up! up! up!",
    "in_game__secret_edward": "Hi Edward!!",
    "heightmarker__secret_gorillas": "How many gorillas can u find??",
    "heightmarker__change_character": "Change Character",
    "heightmarker__change_name": "Change Name",
    "heightmarker__change_trail": "Get a trail!",
    "heightmarker__global_leaderboard": "Global Leaderboard",
    "heightmarker__your_stats": "YOUR STATS",
    "heightmarker__game_stats": "GAME STATS",
    "heightmarker__tutorial_speed": "Speed",
    "heightmarker_tutorial_boost": "Boost!",
    "heightmarker__tutorial_climb": "CLIMB",
    "heightmarker__tutorial_the_wall": "THE WALL",
    "heightmarker__tutorial_bouncy": "Bouncy!",
    "in_game__secret_invis_maze": "Next to the maze door, on the left...",
    "in_game__secret_upside_down_plate": "Jump forward from the\n     upside-down plate!",
    "in_game__tutorial_start_timer": "Pass the starting line to start your timer",
    "in_game__tutorial_invis_platforms": "There are invisible platforms!",
    "in_game__how_fast_top": "How fast can you get to the top?",
    "in_game__no_touchy": "DO NOT TOUCH!",
    "in_game__tutorial_running": "Use left stick to run",
    "in_game__tutorial_tap": "Tap",
    "in_game__tutorial_press": "Press",
    "in_game__tutorial_or": "or",
    "in_game__tutorial__to_jump": "To Jump",
    "in_game__tutorial__hold": "Hold",
    "in_game__tutorial__jump_repeatedly": "To jump repeatedly",
    "in_game__tutorial_wall_climb": "To climb walls",
    "in_game__tutorial_while_running": "While Running",
    "in_game__tutorial_drag_move_camera": "Drag screen to \n move camera",
    "in_game__checkpoint": "Checkpoint!",
    "loading_text__connecting": "Connecting to server...",
    "loading_text__splines": "Reticulating splines",
    "loading_text__tofu": "Marinating the tofu",
    "loading_text__corn": "Shucking the corn",
    "loading_text__broccoli": "Confirming that broccoli is little trees",
    "loading_text__brussel_sprouts": "Convincing players that brussel sprouts are delicious",
    "loading_text__secret_sauce": "Adding the secret sauce",
    "loading_text__digesting": "Digesting",
    "loading_text__stirring": "Stirring constantly",
    "loading_text__fixing_ice_cream": "Fixing the ice cream machine at McDonalds",
    "loading_text__chopping_broccoli": "Chopping the broccoli",
    "loading_text__carmelize_apples": "Carmelizing the apples",
    "loading_text__water_boil": "Watching the water boil",
    "loading_text__filleting_fish": "Filleting the fish",
    "loading_text__plating": "Plating",
    "loading_text__garnishing": "Garnishing",
    "loading_text__moldy_bits": "Cutting off the moldy bits",
    "death_block__almost": "Almost!",
    "death_block__close_one": "Close One!",
    "death_block__try_again": "Try Again!",
    "death_block__roasted": "Roasted!",
    "death_block__burnt": "Burnt!",
    "death_block__oh_no": "Oh No!",
    "death_block__grilled": "Grilled!",
    "death_block__blanched": "Blanched!",
    "death_block__crisp": "Crisp!",
    "death_block__flameed": "Flambéed!",
    "death_block__order_up": "Order Up!",
    "death_block__ouch": "Ouch!",
    "death_block__good_try": "Good Try!",
    "death_block__baked": "Baked!",
    "death_block__crusty": "Crusty!",
    "death_block__next_time": "Next Time!",
    "death_block__toast": "Toasty!",
    "death_block__no_chef": "No Chef!",
    "death_block__singe_left": "Just a singe to the left!",
    "death_block__oof": "Oof!",
    "death_block__fried": "Fried!",
    "death_block__recipe_ruined": "Recipe Ruined",
    "death_block__sizzling_stop": "A Sizzling Stop!",
    "death_block__defeated": "Chef Defeated!",
    "death_block__burnt_out": "Burnt Out?",
    "death_block__meltdown": "Having a Meltdown?",
    "falling_death__slippery": "Slippery!",
    "falling_death__gg": "GG",
    "falling_death__you_died": "You Died",
    "falling_death__f": "F",
    "falling_death__floor_food": "Floor food!",
    "falling_death__three_second": "Three Second Rule!",
    "falling_death__floor_seasoning": "Mmm! Floor Seasoning!",
    "falling_death__just_missed": "Just Missed!",
    "falling_death__rip": "RIP",
    "falling_death__floor_vs_flavor": "Floor: 1",
    "falling_death__floor_fall": "Floor Fall!",
    "falling_death__saucy_slip": "Saucy Slip!",
    "falling_death__fried_fell": "Fried and Fell!",
    "falling_death__dive": "Tasteless Dive!",
    "falling_death__floor_finish": "Floor Finish!",
    "falling_death__dinner_dive": "Dinner Dive!",
    "falling_death__splat": "SPLAT!",
    "falling_death__oops": "Oops",
    "falling_death__greasy_spot": "You found the greasy spot!",
    "falling_death__barely_missed": "Barely Missed!",
    "falling_death__down_we_go": "Down we go!",
    "button__play": "Play!",
    "restart__title": "Restart?",
    "restart__are_you_sure": "Are you sure you want to restart your run?",
    "restart__confirm": "Yes!",
    "restart__restarted": "Restarted...",
    "leaderboard__players": "Players",
    "leaderboard__countries": "Countries",
    "leaderboard__falls": "Falls",
    "leaderboard__hours_played": "Hours Played",
    "leaderboard__got_to_top": "Got To The Top",
    "leaderboard__personal_best": "Personal Best",
    "leaderboard__global_rank": "Global Rank",
    "leaderboard__rank": "Rank",
    "leaderboard__runs": "Runs",
    "leaderboard__time_played": "Time Played"
}

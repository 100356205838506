{
    "error_screen__renderer_failed": "Your graphics card may not support UpTogether! Please try on again on a WebGL2 enabled browser & device.",
    "discord_reward": "特別なスキンをアンロックするために私たちのDiscordに参加してください！",
    "error_screen__server_maybe_full": "サーバーへの接続エラー：サーバーが現在いっぱいかもしれません。数分後にもう一度試してみてください！",
    "error_screen__maintenance_mode": "UpTogether.ioは更新中です！数分後に戻って新しいものを試してみてください 🎉",
    "error_screen__client_out_of_date": "ゲームクライアントが古いです！ゲームクライアントを最新バージョンに更新して再試行してください。",
    "error_screen__invalid_connection": "サーバーがユーザーを拒否しました！もう一度試してください。",
    "error_screen__already_logged_in": "すでにログインしています！",
    "error_screen__unknown_reason": "不明な接続エラー :(",
    "error_screen__disconnected_from_server": "サーバーから切断されました！サーバーはアップデートのために停止しているかもしれません。数分後にもう一度試してみてください。",
    "heightmarker__to_bottom": "一番下に戻る！",
    "heightmarker__inspiration_come_far": "これまでに来た道を見て！",
    "heightmarker__inspiration_keep_going": "続けて！",
    "heightmarker__inspiration_up_up": "上へ！上へ！上へ！",
    "in_game__secret_edward": "こんにちは、エドワード！！",
    "heightmarker__secret_gorillas": "ゴリラは何匹見つけられるかな？？",
    "heightmarker__change_character": "キャラクター変更",
    "heightmarker__change_name": "名前変更",
    "heightmarker__change_trail": "トレイルを手に入れよう！",
    "heightmarker__global_leaderboard": "グローバルリーダーボード",
    "heightmarker__your_stats": "あなたの統計",
    "heightmarker__game_stats": "ゲーム統計",
    "heightmarker__tutorial_speed": "スピード",
    "heightmarker_tutorial_boost": "ブースト！",
    "heightmarker__tutorial_climb": "登る",
    "heightmarker__tutorial_the_wall": "壁",
    "heightmarker__tutorial_bouncy": "バウンシー！",
    "in_game__secret_invis_maze": "迷路の扉の横、左に...",
    "in_game__secret_upside_down_plate": "逆さまのプレートから前にジャンプ！",
    "in_game__tutorial_start_timer": "スタートラインを越えてタイマーを開始します",
    "in_game__tutorial_invis_platforms": "見えないプラットフォームがあります！",
    "in_game__how_fast_top": "どれだけ速くトップに到達できますか？",
    "in_game__no_touchy": "触らないで！",
    "in_game__tutorial_running": "左のスティックを使って走る",
    "in_game__tutorial_tap": "タップ",
    "in_game__tutorial_press": "押す",
    "in_game__tutorial_or": "または",
    "in_game__tutorial__to_jump": "ジャンプするには",
    "in_game__tutorial__hold": "ホールド",
    "in_game__tutorial__jump_repeatedly": "繰り返しジャンプするには",
    "in_game__tutorial_wall_climb": "壁を登るには",
    "in_game__tutorial_while_running": "走りながら",
    "in_game__tutorial_drag_move_camera": "画面をドラッグして\n カメラを移動",
    "in_game__checkpoint": "チェックポイント！",
    "loading_text__connecting": "サーバーに接続中...",
    "loading_text__splines": "スプラインを絡ませています",
    "loading_text__tofu": "豆腐を漬け込んでいます",
    "loading_text__corn": "とうもろこしをむいています",
    "loading_text__broccoli": "ブロッコリーが小さな木であることを確認しています",
    "loading_text__brussel_sprouts": "ブリュッセルスプラウトが美味しいと説得しています",
    "loading_text__secret_sauce": "秘密のソースを加えています",
    "loading_text__digesting": "消化中",
    "loading_text__stirring": "絶えずかき混ぜています",
    "loading_text__fixing_ice_cream": "マクドナルドのアイスクリームマシンを修理しています",
    "loading_text__chopping_broccoli": "ブロッコリーを切っています",
    "loading_text__carmelize_apples": "リンゴをキャラメルにしています",
    "loading_text__water_boil": "お湯が沸騰しているのを見ています",
    "loading_text__filleting_fish": "魚をさばいています",
    "loading_text__plating": "盛り付け中",
    "loading_text__garnishing": "飾り付け中",
    "loading_text__moldy_bits": "カビの生えた部分を切り落としています",
    "death_block__almost": "ほぼ！",
    "death_block__close_one": "接近した！",
    "death_block__try_again": "もう一度やってみて！",
    "death_block__roasted": "焼かれました！",
    "death_block__burnt": "焦げました！",
    "death_block__oh_no": "ああ、だめ！",
    "death_block__grilled": "焼きました！",
    "death_block__blanched": "白くなりました！",
    "death_block__crisp": "カリカリです！",
    "death_block__flameed": "フランベ！",
    "death_block__order_up": "オーダーアップ！",
    "death_block__ouch": "痛い！",
    "death_block__good_try": "よくやった！",
    "death_block__baked": "焼かれました！",
    "death_block__crusty": "カリカリです！",
    "death_block__next_time": "次回に期待！",
    "death_block__toast": "あなたはトーストです！",
    "death_block__no_chef": "シェフなし！",
    "death_block__singe_left": "左に少し火をつけただけです！",
    "death_block__oof": "オフ！",
    "death_block__fried": "つまずいて揚げました！",
    "death_block__recipe_ruined": "レシピが台無しです",
    "death_block__sizzling_stop": "ジュージュー音が止まりました！",
    "death_block__defeated": "シェフ敗北！",
    "death_block__burnt_out": "燃え尽きましたか？",
    "death_block__meltdown": "メルトダウン中？",
    "falling_death__slippery": "滑りやすい！",
    "falling_death__gg": "GG",
    "falling_death__you_died": "あなたは死にました ",
    "falling_death__f": "F",
    "falling_death__floor_food": "床の食べ物！",
    "falling_death__three_second": "3秒ルール！",
    "falling_death__floor_seasoning": "うーん！床の調味料！",
    "falling_death__just_missed": "ちょうど逃した！",
    "falling_death__rip": "お疲れ様でした",
    "falling_death__floor_vs_flavor": "床：1",
    "falling_death__floor_fall": "床失敗！",
    "falling_death__saucy_slip": "ソースの滑り！",
    "falling_death__fried_fell": "揚げて倒れました！",
    "falling_death__dive": "無味のダイブ！",
    "falling_death__floor_finish": "床フィニッシュ！",
    "falling_death__dinner_dive": "ディナーダイブ！",
    "falling_death__splat": "スプラット！",
    "falling_death__oops": "おっと",
    "falling_death__greasy_spot": "油っぽいスポットを見つけました！",
    "falling_death__barely_missed": "かろうじて逃した！",
    "falling_death__down_we_go": "下へ行くよ!",
    "button__play": "プレイ！",
    "restart__title": "リスタート？",
    "restart__are_you_sure": "本当にランをリスタートしますか？",
    "restart__confirm": "はい！",
    "restart__restarted": "リスタートしました...",
    "leaderboard__players": "プレイヤー",
    "leaderboard__countries": "国",
    "leaderboard__falls": "転倒",
    "leaderboard__hours_played": "プレイ時間",
    "leaderboard__got_to_top": "トップに到達",
    "leaderboard__personal_best": "個人ベスト",
    "leaderboard__global_rank": "グローバルランク",
    "leaderboard__rank": "ランク",
    "leaderboard__runs": "プレイ回数",
    "leaderboard__time_played": "プレイ時間"
}
